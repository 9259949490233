export const SDNO_COMPANIES_QUERY = `query($after: String){
  sdnoCompanies(first: 1000, after: $after, isActive: true, hasSubscriptions: true) {
    edges {
      node {
        value: id
        displayName: name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`


export const MDM_COMPANIES_QUERY = `query($after: String){
  mdmCompanies(first: 20000, after: $after) {
    edges {
      node {
        value: nodeId
        displayName: name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`

export const MDM_BRANDS_QUERY = `query($nodeIds: [Any!]) {
  mdmCompanies(filter: {
      nodeId: {
        in: $nodeIds
      }
    }) {
    edges {
      node {
        nodeId
        relationships {
          tree
        }
      }
    }
  }
}`


export const SDNO_COMPANY_BRANDS_QUERY = `query($companyId: String!) {
  sdnoCompany(id: $companyId) {
    edges {
      node {
        brands {
          val {
            value: id
            displayName: name
            externalReferences {
              val {
                system
              }
            }
          }
        }
      }
    }
  }
}`


export const BRANDS_QUERY = `query($name: String) {
  brands(filter: {name: {eq: $name}}) {
    value:id
    displayName:name
  }
}
`

export const DEPARTMENTS_NEW_QUERY = `query {
  departments:sdnDeliveryPeriodSnapshotDepartments(first: 1000) {
    edges {
      node {
        value:tag
        displayName:description
        categories {
          edges {
            node {
              value:tag
              displayName:description
              subcategories {
                edges {
                  node {
                    value:tag
                    displayName:description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const SUBSCRIPTIONS_QUERY = `query {
    csGlobalEmailList:cnsGlobalEmailList
    data:cnsSubscription {
        id
        filePrefixName
        brands {
          id
          displayName
        }
        departments {
          id
          displayName
        }
        categories {
          id
          displayName
        }
        subcategories {
          id
          displayName
        }
        toEmailRecipients
        ccEmailRecipients
        dataGroup
        dataGroupId
        productUniverses
        aggregationPeriods
    } 
}`

export const MARKETS_QUERY = `
    query markets {
        markets {            
            market_key        
            description
        }
    }`

export const MARKETS_QUERY_BY_INDEX = `
    query markets ($index: [Int]) {
        markets (index: $index) {                             
            value: index            
            displayName: description
        }
    }`

export const DESIGNATIONS_QUERY = `
    query designations ($designationIds: [Int!]) {
        designations(designationIds: $designationIds){
            value: index
            displayName: name
        }
    }`


export const ADMIN_OPTIONS_QUERY = `
    query($includeSdnoMapping: Boolean) {
        cnsAdminOptions(includeSdnoMapping: $includeSdnoMapping){
            marketKeysIndex
            designations
            wholesalerRegionMapping {
                marketKey
                defaultValue
                overrideValue
                sdnoValue
            }
        }        
    }`


export const SUBSCRIPTIONS_QUERY_EXPANDED = `query($id: String) {
    data:cnsSubscription(id: $id) {
        id
        filePrefixName
        toEmailRecipients
        ccEmailRecipients
        dataGroup
        dataGroupId
        productUniverses
        aggregationPeriods
        companies {
          value:id
          displayName
        }
        brands {
          value:id
          displayName
        }
        departments {
          value:id
          displayName
        }
        categories {
          value:id
          displayName
        }
        subcategories {
          value:id
          displayName
        }
    } 
}`

export const VM_INSTANCE_QUERY = `query CnsVmInstances {
  data:cnsVmInstances {
    createDate
    name
    status
  }
}`