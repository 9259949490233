import React, {useEffect, useState} from 'react';
import {makeApolloClientCall} from "../../api/makeApolloApiCall.js";
import SatoriExtractCreatedDate from "../display/SatoriExtractCreatedDate.jsx";
import {Table} from "../tables/Table.jsx";
import Page from "../Page.jsx";
import {mergeRetailerAndMarketDataWithUser} from "./mergeDataUtils.js";
import Auth0CellContainer from "../display/Auth0CellContainer.jsx";
import salesforceLogo from "../../assets/Salesforce.com_logo.svg.png"
import MarketInsightsCellContainer from "../display/MarketInsightsCellContainer.jsx";
import StoreInsightsCellContainer from "../display/StoreInsightsCellContainer.jsx";

const COLUMNS = [
    {
        Header: 'Satori Username',
        accessor: 'username'
    },
    {
        Header: 'Email Address',
        accessor: 'email'
    },
    {
        accessor: 'sfUser',
        Cell: (props) => {
            return props.row.original?.sfUser ?
                <div style={{'width': '50px'}}>
                    <img alt={'salesforce'} className={'salesforce-logo'} src={salesforceLogo}/>
                </div> : <></>
        },
        disableFilters: true,
    },
    {
        Header: 'Organization Name',
        accessor: 'organizationName'
    },
    {
        Header: 'Company',
        accessor: 'companyName'
    },
    {
        Header: 'Active TPA',
        accessor: 'companyTpa'
    },
    {
        Header: 'Permissions',
        columns: [
            {
                Header: 'Market Insights',
                accessor: 'market insights',
                Cell: (props) => {
                    return (
                        <MarketInsightsCellContainer row={props.row.original}/>
                    )
                },
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Store Insights',
                accessor: 'store insights',
                Cell: (props) => {
                    return (
                        <StoreInsightsCellContainer row={props.row.original}/>
                    )
                },
                disableFilters: true,
                disableSortBy: true
            }
        ]

    },
    {
        Header: 'Auth0',
        accessor: 'markets and retailers',
        Cell: (props) => {
            return (
                <Auth0CellContainer row={props.row.original}/>
            )
        },
        disableFilters: true,
        disableSortBy: true
    }
]

const Permissions = () => {
    const [users, setUsers] = useState([])
    const [userDataIsLoading, setUserDataIsLoading] = useState(true)
    const [createdDate, setCreatedDate] = useState('Unknown')

    const permissionsQuery = `query Users {
        satoriUsers {
            createdDate
            satoriUser {
                email
                username
                organizationName
                geoFamilyIds
                retailTags
                brands
            }
        }
        retailers {
            retailerId
            retailTag
            retailChain
        }
        geoFamilyIds {
            geoFamilyId {
                geoFamilyId
                marketList
            }
        }
        markets {
            market_key
            description
        }
    }`

    const subcategoriesQuery = `query Subcategories {
        satoriUsers {
            satoriUser {
                username
                subcategories
            }
        }
    }`

    useEffect(() => {
        makeApolloClientCall(permissionsQuery).then((data) => {
                makeApolloClientCall(subcategoriesQuery).then((subcats) => {
                    setCreatedDate(data.satoriUsers?.createdDate ?? 'Unknown')
                    setUsers(mergeRetailerAndMarketDataWithUser(
                        data.satoriUsers?.satoriUser,
                        data.retailers,
                        data.markets,
                        data.geoFamilyIds?.geoFamilyId,
                        data.users ?? [],
                        subcats.satoriUsers?.satoriUser)
                    )

                    setUserDataIsLoading(false)
                })
            })
    }, [permissionsQuery, subcategoriesQuery])
    return userDataIsLoading ? (<div>Loading...</div>) :
        (
            <Page name='Permissions'>
                <div className={'table-container'}>
                    <div className={'data-table'}>
                        <SatoriExtractCreatedDate createdDate={createdDate}/>
                        <Table columns={COLUMNS} data={users}/>
                    </div>
                </div>
            </Page>
        )
};

export default Permissions;