import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, TextField } from "@mui/material";
import { ComboBox } from "../../comboBox/ComboBox.jsx";
import * as React from "react";
import { useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close.js";
import { toast, ToastContainer } from "react-toastify";
import axios from "../../../api/axiosApiClient";

export const REQUEST_ENDPOINT = `${process.env.REACT_APP_RIS_API_URL}/mid-market-report`;

const initData = (data) => {
  return {
    name: data?.name ?? "",
    marketKeys: data?.marketKeys ?? [],
    compMarketKeys: data?.compMarketKeys ?? [],
    accountManager: data?.accountManager,
    dataGroup: data?.dataGroup,
    id: data?.id,
  };
};
export const RetailerDataModal = ({
  isOpen,
  handleClose,
  data,
  setRetailers,
  marketKeyList,
  dataGroupList,
  sdnoInternalUserList
}) => {
  const [formData, setFormData] = useState(initData(data));
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData(initData(data));
  }, [data]);

  const validateForm = (values) => {
    const REQUIRED = "This field is required.";
    let errors = {};
    ["name", "dataGroup"].forEach((field) => {
      if (!values[field]) {
        errors[field] = REQUIRED
      }
    });

    ["marketKeys", "compMarketKeys"].forEach((field) => {
      if (!values[field]?.length) {
        errors[field] = REQUIRED
      }
    });

    return errors;
  };

  const formOnchange = (formKey, formValue) => {
    formData[formKey] = formValue
    setFormErrors({...formErrors, [formKey]: undefined})
    setFormData({...formData, formData})
  };

  const getValue = (data) => parseInt(data.value)
  const handleSave = async () => {
    const dataGroup = formData?.dataGroup ? {dataGroupId: formData?.dataGroup} : {}
    const payload = {
      name: formData.name,
      marketKeys: formData?.marketKeys.map(getValue),
      compMarketKeys: formData?.compMarketKeys.map(getValue),
      accountManager: {
        email: formData?.accountManager?.email,
        fname: formData?.accountManager?.fname,
        id: formData?.accountManager?.id,
        lname: formData?.accountManager?.lname,
        type: "Account Manager"
      },
      id: formData?.id,
      ...dataGroup
    };

    try {
      const { data } = await axios.post(
        `${REQUEST_ENDPOINT}/retailer/upsert-config`,
        payload
      );

      setRetailers(data);
      toast.success("Updated record successfully.");
    } catch (e) {
      toast.error("Error while updating record.");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        "data-testid": "modal-dialog",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {formData?.id ? "Edit" : "Add"}
        <IconButton
          data-testid="modal-close"
          onClick={handleClose}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "16px" }}>
        <div>
          <ToastContainer />
          <form
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <TextField
              label={"Name"}
              data-testid={"name-field"}
              variant="outlined"
              fullWidth={true}
              value={formData.name}
              onChange={(event) => formOnchange("name", event.target.value)}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
            <ComboBox
              label="Data Group ID"
              testId={"datagroup-field"}
              value={!formData.dataGroup ? null : dataGroupList.find(c => c?.value === formData?.dataGroup)}
              isMultiple={false}
              disableCloseOnSelect={false}
              data={dataGroupList}
              onChange={(event, value) => formOnchange("dataGroup", value?.value)}
              error={formErrors.dataGroup}
            />
            <ComboBox
                label="Account Manager"
                testId={"account-manager-field"}
                value={!formData.accountManager ? null : sdnoInternalUserList.find(c => c?.value === formData?.accountManager.id)}
                isMultiple={false}
                disableCloseOnSelect={false}
                data={sdnoInternalUserList}
                onChange={(event, value) => formOnchange("accountManager", value)}
                error={formErrors.accountManager}
            />
            <ComboBox
              label="Market Keys"
              testId={"marketkeys-field"}
              value={formData.marketKeys}
              isMultiple={true}
              disableCloseOnSelect={false}
              data={marketKeyList}
              onChange={(event, value) => formOnchange("marketKeys", value)}
              error={formErrors.marketKeys}
            />
            <ComboBox
              label="Comparative Market Keys"
              testId={"compmarket-field"}
              value={formData.compMarketKeys}
              isMultiple={true}
              disableCloseOnSelect={false}
              data={marketKeyList}
              onChange={(event, value) => formOnchange("compMarketKeys", value)}
              error={formErrors.compMarketKeys}
            />
          </form>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "0 16px 16px 0" }}>
        <Button
          size="large"
          variant="outlined"
          onClick={() => {
            const errors = validateForm(formData);
            if (Object.keys(errors).length > 0) {
              setFormErrors(errors);
            } else {
              handleSave().then();
            }
          }}
          data-testid={"save-button"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
