export const generateSubscriptions = (productLevels, departments, categories, subcategories) => {
    const result = []
    productLevels.forEach((productLevel) => {
        switch (productLevel.value) {
            case "DEPARTMENT":
                if (departments.some(d => d)) {
                    result.push(...departments.map((department) => (
                        {
                            productLevel,
                            department,
                        }
                    )))
                }
                break
            case "CATEGORY":
            case "BRANDCAT":
                result.push(...categories.map((category) => (
                    {
                        productLevel,
                        category
                    }
                )))
                break
            case "SUBCATEGORY":
            case "BRAND":
            case "UPC":
                result.push(...subcategories.map((subcategory) => (
                    {
                        productLevel,
                        subcategory
                    }
                )))
                break
            default:
                break
        }
    })

    return result
}
