import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Button, IconButton, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

export const AddEditItemModal = (
    {
        title,
        fields,
        isOpen,
        onClose = () => {},
        onSubmit = (_) => {},
        initialValues = {},
        uneditable = [],
        validateAndEnableSave = (_) => true,
    }
) => {
    const [items, setItems] = useState(() => Object.fromEntries(fields.map(f => [f, initialValues ? initialValues[f] || "" : ""])));
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (initialValues != null && Object.keys(initialValues).length !== 0) {
            setItems(() => Object.fromEntries(fields.map(f => [f, initialValues ? initialValues[f] || "" : ""])))
        }
    }, [fields, initialValues]);

    const clear = () => {
        setItems(() => Object.fromEntries(fields.map(f => [f, ""])))
        setMessage(() => "")
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div className={"account-modal-form"}>
                    <form className={"admin-options"}>
                        {fields.map(f => {
                            return <TextField
                                key={f}
                                disabled={uneditable.includes(f)}
                                label={f}
                                variant="outlined"
                                fullWidth={true}
                                value={items[f]}
                                onChange={(event) => {
                                    setItems((prevState) => {
                                        const value = {
                                            ...prevState
                                        }
                                        value[f] = event.target.value
                                        return value
                                    })
                                }}
                            />
                        })
                        }
                        <span style={{color: 'red'}}>{message?.length !== 0 ? message : ""}</span>
                    </form>
                </div>
            </DialogContent>
            <DialogActions className={"account-dialog-action"}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose()
                        clear()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!validateAndEnableSave(items)}
                    variant="contained"
                    onClick={() => {
                        const message = onSubmit(items)
                        if (message && message?.length !== 0) {
                            setMessage(message)
                        } else {
                            onClose()
                            clear()
                        }
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
