export const DEFAULT_DATA_GROUP = "C&S Syndicate";
export const DEFAULT_DATA_GROUP_ID = "13524";
export const PRODUCT_UNIVERSES = "TPL";
export const DEFAULT_AGGREGATION_PERIODS = [
    {
        displayName: "4 WEEKS",
        value: "4 WEEKS"
    },
    {
        displayName: "12 WEEKS",
        value: "12 WEEKS"
    },
    {
        displayName: "24 WEEKS",
        value: "24 WEEKS"
    },
    {
        displayName: "52 WEEKS",
        value: "52 WEEKS"
    }
]

export const ALL_OPTIONS = "ALL"

export const DEFAULT_SUBCATEGORIES  = {
    value: ALL_OPTIONS,
    displayName: "ALL SUBCATEGORIES",
}

export const DEFAULT_CATEGORIES = {
    value: ALL_OPTIONS,
    displayName: "ALL CATEGORIES",
    subcategories: {
        edges: [
            {
                node: DEFAULT_SUBCATEGORIES
            }
        ]
    }
}

export const DEFAULT_DEPARTMENT = {
    value: ALL_OPTIONS,
    displayName: "ALL DEPARTMENTS",
    categories: {
        edges: [
            {
                node: DEFAULT_CATEGORIES
            }
        ]
    }
}

