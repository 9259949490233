import React, {useEffect, useState} from 'react'
import {Table} from "../../tables/Table.jsx"
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js"
import Page from "../../Page.jsx";
import {mapCompanies} from "../mergeDataUtils.js";

const COLUMNS = [
    {
        Header: 'Company Name',
        accessor: 'name',
        Cell: row => <div className={'column-align-left'}>{row.value}</div>
    },
    {
        Header: 'Salesforce Client ID',
        accessor: 'salesforceId'
    },
    {
        Header: 'Contract Status',
        accessor: 'enabled'
    },
    {
        Header: 'Contract End Date',
        accessor: 'contractEndDate'
    },
    {
        Header: 'Contract Number',
        accessor: 'contractNumber'
    },
    {
        Header: 'Master Contract Number',
        accessor: 'masterContractNumber'
    }
]

export const CompanyContracts = () => {
    const [companyDataIsLoading, setCompanyDataIsLoading] = useState(true)
    const [companies, setCompanies] = useState([])

    const companyQuery = `query {
        companies {
            name
            id
            contracts {
                contractNumber
                contractEndDate
                contractStatus
                masterContractNumber
            }
        }
        satoriCompanies {
            satoriCompanies {
                organizationName
                salesForceClientId
                enabled
                contractEndDate
                contractNumber
            }
        }
    }`

    useEffect(() => {
        makeApolloClientCall(companyQuery)
            .then((data) => {
                setCompanies(mapCompanies(
                    data.companies ?? {},
                    data.satoriCompanies ?? {}))
                setCompanyDataIsLoading(false)
            })
    }, [companyQuery])

    return (companyDataIsLoading) ? (<div>Loading...</div>) :
        (
            <Page name='Company Contracts'>
                <div className={'table-container'}>
                    <Table columns={COLUMNS} data={companies}/>
                </div>
            </Page>
        )
}

