const ARRAY_FIELDS_TO_CHECK = [
    "brands",
    "toEmailRecipients",
    "companies"
]

const STRING_FIELDS_TO_CHECK = [
    "filePrefixName"
]

export const REQUIRED = "This field is required.";
export const validate = (values) => {
    let errors = {};
    STRING_FIELDS_TO_CHECK.forEach((field) => {
        if (!values[field]) {
            errors[field] = REQUIRED
        }
    })

    ARRAY_FIELDS_TO_CHECK.forEach((field) => {
        if (!values[field]?.length) {
            errors[field] = REQUIRED
        }
    })

    return errors;
};
