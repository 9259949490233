import React, {useState} from 'react';
import Modal from 'react-modal';
import PropTypes from "prop-types";

const BaseModal = ({children, buttonClass, openButtonTitle, closeButtonTitle, headerText, customStyles}) => {
    const [modalAction, handleModalAction] = useState(false)

    function handleOpenModal() {
        handleModalAction(true)
    }

    function handleCloseModal() {
        handleModalAction(false)
    }

    return (
        <div>
            <button className={`button ${buttonClass}`} onClick={handleOpenModal}>{openButtonTitle}</button>
            <Modal
                style={customStyles}
                isOpen={modalAction}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                contentLabel="Minimal Modal Example"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className={"modal-header"}><div><h5>{headerText}</h5></div></div>
                <div className={'modal-content'}>{children}</div>
                <button className={'button modal-button cancel'} onClick={handleCloseModal}>{closeButtonTitle}</button>
            </Modal>
        </div>)
        ;
};

BaseModal.defaultProps = {
    buttonClass: 'secondary',
    closeButtonTitle: 'Close',
    openButtonTitle: 'Open Modal'
};

BaseModal.propTypes = {
    closeButtonTitle: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    openButtonTitle: PropTypes.string
};

export default BaseModal;