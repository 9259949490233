import { toast, ToastContainer } from "react-toastify";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { ComboBox } from "../../../comboBox/ComboBox.jsx";
import { fetchPeriod } from "../../noshe/AvailableScorecards.jsx";
import axios from "axios";
import ConfirmationDialog from "../../common/dialog/ConfirmationDialog.jsx";

const BRAND_LIST_API = `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/email/brands`;
const SEND_EMAIL_NOTIFICATIONS_API = `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/email/send-notifications`;

const SCORECARD_VENDORS = [
	{ value: "JBP", displayName: "JBP" },
	{ value: "INFRA", displayName: "INFRA" },
	{ value: "NCG", displayName: "NCG" },
];

export const EmailNotification = () => {
	const auth0AccessToken = window.localStorage.getItem("access_token");
	const [isLoading, setIsLoading] = useState(true);
	const [year, setYear] = useState(null);
	const [period, setPeriod] = useState(null);
	const [userListVendors, setUserListVendors] = useState([]);
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [brandList, setBrandList] = useState([]);
	const [excludedBrandList, setExcludedBrandList] = useState([]);
	const [openSendEmailModal, setOpenSendEmailModal] = useState(false);

	const generateUsers = () => {
		setIsLoading(true)
		const LAMBDA_ENDPOINT = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/lambda`;
		const generateUserCalls = userListVendors.map(vendor => {
			const payload = {
				period: parseInt(period.toString()),
				year: parseInt(year.toString()),
				vendor: vendor.value,
			};
			return axios.post(
					`${LAMBDA_ENDPOINT}/ris-scorecards-service-dev-periodReleaseEmailHandler`,
					{ payload: JSON.stringify(payload) },
					{
						headers: {
							authorization: `Bearer ${auth0AccessToken}`,
							contentType: "application/json",
							accept: "application/json",
						},
						timeout: 300000
					}
				)
		})

		Promise.all(generateUserCalls).then(results => {
			const s3Uris = results.map(result => result?.data).join(", ")
			toast.success(`Successfully generated the user email list: ${s3Uris}`);
		})
		.catch((err) => {
			toast.error(`Error in generating the user email list: ${err}`);
		}).finally(() => setIsLoading(false));
	};

	const getBrandList = (vendor, year, period, setBrandList) => {
		axios
			.get(BRAND_LIST_API, {
				headers: {
					authorization: `Bearer ${auth0AccessToken}`,
					contentType: "application/json",
					accept: "application/json",
				},
				params: { vendor: vendor.value, year, period },
			})
			.then(({ data }) => {
				const brandList = data.map(brand => ({value: brand, displayName: brand}))
				setBrandList(brandList);
			})
			.catch((err) => {
				toast.error(`Error getting brands list: ${err}`);
				setBrandList([]);
			});
	};

	const sendEmail = () => {
		const payload = {
			period: period,
			year: year,
			vendor: selectedVendor.value,
			excludedBrands: excludedBrandList.map(brand => brand.value)
		}
		axios
			.post(
				`${SEND_EMAIL_NOTIFICATIONS_API}`,
				payload,
				{
					headers: {
						authorization: `Bearer ${auth0AccessToken}`,
						contentType: "application/json",
						accept: "application/json",
					},
				}
			)
			.then(_ => {
				toast.success(`Successfully sent email notifications`);
			})
			.catch((err) => {
				toast.error(`Error sending email notifications: ${err}`);
			}).finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (selectedVendor) {
			getBrandList(selectedVendor, year, period, setBrandList);
		}
	}, [selectedVendor]);

	useEffect(() => {
		fetchPeriod()
			.then(({ year, periodNumber }) => {
				setYear(year);
				setPeriod(periodNumber);
			})
			.finally(() => setIsLoading(false));
	}, []);

	if (isLoading) {
		return (
			<CircularProgress
				className={"spinning-loader"}
				data-testid={"main-loader"}
			/>
		);
	}

	return (
		<div>
			<ToastContainer />
			<div>
				<form>
					<div className={"email-container"}>
						<h4 style={{ marginBottom: "20px" }}>
							{" "}
							Generate User List{" "}
						</h4>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="Year"
									value={year}
									fullWidth={true}
									onChange={(event) =>
										setYear(event.target.value)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Period"
									value={period}
									fullWidth={true}
									onChange={(event) =>
										setPeriod(event.target.value)
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<ComboBox
									label="Vendor"
									value={userListVendors}
									isMultiple={true}
									disableCloseOnSelect={false}
									data={SCORECARD_VENDORS}
									onChange={(event, value) =>
										setUserListVendors(value)
									}
								/>
							</Grid>
						</Grid>
						<Button
							style={{ marginTop: "10px" }}
							onClick={generateUsers}
							disabled={year == null || period == null || userListVendors.length <= 0}
						>
							Generate
						</Button>
					</div>

					<div className={"email-container"}>
						<h4 style={{ marginBottom: "20px" }}>
							Send Email Notifications
						</h4>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ComboBox
									label="Vendor"
									value={selectedVendor}
									isMultiple={false}
									disableCloseOnSelect={false}
									data={SCORECARD_VENDORS}
									onChange={(event, value) => {
										setSelectedVendor(value);
										setExcludedBrandList([]);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<ComboBox
									label="Excluded Brands"
									value={excludedBrandList}
									isMultiple={true}
									disableCloseOnSelect={false}
									data={brandList}
									onChange={(event, value) =>
										setExcludedBrandList(value)
									}
								/>
							</Grid>
						</Grid>
						<Button
							style={{ marginTop: "10px" }}
							onClick={() => setOpenSendEmailModal(true)}
							disabled={!selectedVendor}
						>
							Send
						</Button>
					</div>
				</form>
			</div>
			<ConfirmationDialog
				isOpen={openSendEmailModal}
				message={"Are you sure you want to trigger the mail sending?"}
				handleClose={(value) => setOpenSendEmailModal(value)}
				handleConfirm={(value) => {
					setOpenSendEmailModal(false)
					if (value) {
						setIsLoading(true)
						sendEmail()
					}
				}}
				title={"Trigger Email Sending"}
			/>
		</div>
	);
};
