import * as React from "react";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import moment from 'moment'
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import {AuditLogTable} from "../../auditLogTable/AuditLogTable.jsx";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import {makeApolloClientCall as nirvanaApolloClientCall} from "../../../helpers/queryNirvanaCoreApi.js";
import {UPDATE_CONTRACT_END_DATE} from "./mutation.js";
import {toast} from "react-toastify";
import {Edit} from "@mui/icons-material";
import axios from "../../../api/axiosApiClient.js";

export const LOG_ACTION_API = `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/audit-log/logs`;
export const ContractInfoModal = ({isOpen, handleClose, data, setContractData, fetchAccounts, setIsRowUpdating}) => {
    const [dialog, setDialog] = useState(false);
    const [nameDialog, setNameDialog] = useState(false);
    const [companyName, setCompanyName] = useState('')
    const [editCompanyName, setEditCompanyName] = useState(false)

    const handleUpdateContract = (id, endDate) => {
        const newDate = moment(endDate).add(1, 'year').format('YYYY-MM-DD')

        nirvanaApolloClientCall(UPDATE_CONTRACT_END_DATE, false, {
            input: {
                id,
                endDate: newDate
            },
        })
            .then(({editContract}) => {
                if (editContract) {
                    setContractData((prevState) => ({
                        ...prevState,
                        endDate: newDate,
                    }))
                    toast.success('Contract extended by 1 year.')
                }
            })
            .catch((error) => {
                toast.error(
                    `An error occurred while updating the contract end date ${error}`
                );
            })
    }

    const handleSubmitName = async (name, uuid) => {
        setIsRowUpdating(true)
        setEditCompanyName(false)
        await axios
            .put(
                `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/audit-log/update-company`,
                {
                    companyName: name,
                    companyUuid: uuid
                }
            )
        await fetchAccounts()
        setIsRowUpdating(false)
    }

    return (
        <div className={"contract-info-modal"}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
                data-testid={"contract-info-dialog"}
            >
                <DialogTitle id="alert-dialog-title" className={"account-dialog-title"}>
                    <div>
                        {
                            !editCompanyName ?
                                <>
                                    <span data-testid={"company-name"}>{companyName || data?.companyName}</span>
                                    <Edit
                                        data-testid={"edit-company-name-button"}
                                        onClick={() => {
                                            setEditCompanyName(!editCompanyName);
                                            setCompanyName(data?.companyName)
                                        }}/>
                                </> :
                                <Box sx={{display: 'flex', gap: '8px'}}>
                                    <input
                                        style={{margin: 0}}
                                        type="text"
                                        data-testid={'edit-company-input'}
                                        value={companyName}
                                        onChange={(event) => setCompanyName(event.target.value)}
                                    />
                                    <Button data-testid={"update-company-button"} size="small" onClick={() => setNameDialog(true)}>
                                        Update
                                    </Button>
                                </Box>
                        }
                        <p className={"uuid"}> {data?.companyUuid} </p>
                    </div>
                    <IconButton onClick={handleClose} className={"button-close"} test-id={"contract-modal-close-btn"}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={"contract-info"}>
                        <TableContainer>
                            <Table sx={{width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}><span className={"bold"}> CONTRACT </span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!data?.uuid ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}
                                                       style={{textAlign: "center"}}>
                                                No Contract Found
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <>
                                            <TableRow key={"UUID"}>
                                                <TableCell component="th" scope="row">UUID</TableCell>
                                                <TableCell component="th" scope="row">{data?.uuid}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Contract Number"}>
                                                <TableCell component="th" scope="row">Contract Number</TableCell>
                                                <TableCell component="th" scope="row">{data?.contractNumber}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Start Date"}>
                                                <TableCell component="th" scope="row">Start Date</TableCell>
                                                <TableCell component="th" scope="row">{data?.startDate}</TableCell>
                                            </TableRow>
                                            <TableRow key={"End Date"}>
                                                <TableCell component="th" scope="row">End Date</TableCell>
                                                <TableCell component="th" scope="row" sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    color: moment(data.endDate).diff(new Date(), 'days') < 30 ?
                                                        'red' :
                                                        'inherit'
                                                }}>
                                                    {data?.endDate}
                                                    <Button onClick={() => setDialog(true)}
                                                            data-testid={"contract-button"}>Extend Contract </Button>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer>
                            <Table sx={{width: "100%", marginTop: "15px"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}><span
                                            className={"bold"}> NOSHE SUBSCRIPTION </span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!data?.nosheSubscription?.subscription ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}
                                                       style={{textAlign: "center"}}>
                                                No NOSHE Subscription Found
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <>
                                            <TableRow key={"ID"}>
                                                <TableCell component="th" scope="row">ID</TableCell>
                                                <TableCell component="th"
                                                           scope="row">{data?.nosheSubscription?.id}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Type"}>
                                                <TableCell component="th" scope="row">Type</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {data?.nosheSubscription?.subscription?.name} ({data?.nosheSubscription?.subscription?.uuid})
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <AuditLogTable endpoint={LOG_ACTION_API} params={{company: data?.companyUuid}}/>
                    </div>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog
                isOpen={dialog}
                message={"Extend the current contract by 1 year?"}
                handleClose={() => setDialog(false)}
                handleConfirm={async (value) => {
                    if (value) {
                        handleUpdateContract(data.id, data.endDate)
                    }
                    setDialog(false)
                }}
                title={"Extend Contract?"}
            />
            <ConfirmationDialog
                isOpen={nameDialog}
                message={"Are you sure you want to rename the company?"}
                handleClose={() => setNameDialog(false)}
                handleConfirm={(value) => {
                    if (value) {
                        handleSubmitName(
                            companyName,
                            data.uuid || data.companyUuid
                        )
                    }
                    setNameDialog(false)
                }}
                title={"Rename Company?"}
            />
        </div>
    )
}