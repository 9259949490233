import {Button} from "@mui/material";
import {useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {makeApolloClientCall} from "../../../../../api/makeApolloApiCall.js";

const THRESHOLD = 500000

export const TriggerGenerationButton = ({query, data, buttonText, createExcel, getRawRowCountCallable, disabled = false}) => {
    const [isDisabled, setIsDisabled] = useState(false)

    return <div><Button
        variant="outlined"
        disabled={disabled ? disabled : isDisabled}
        onClick={() => {
            setIsDisabled(true)
            makeApolloClientCall(query, false, {id: data.id, createExcel})
                .then((response) => {
                    const upc_all_data_row_count = getRawRowCountCallable(response)
                    const rowCounts = response.data.dataAccessMetadata.map(({fileName, rowCount}) => <div
                        key={fileName}>{fileName} {rowCount} rows</div>)

                    const content = <div>
                        {rowCounts}
                        <div>CSV Location: {response.data.dataLocation}</div>
                    </div>;

                    if (upc_all_data_row_count >= THRESHOLD) {
                        return toast.error(content, {bodyClassName: "highRowCount"})
                    }
                    return toast.success(content, {bodyClassName: "success"});
                })
                .catch(error => toast.error(`${error}`))
                .finally(() => setIsDisabled(false))
        }
        }>{buttonText}</Button></div>
}
