import React, {useState} from "react";
import Page from "../../Page.jsx";
import {Box, Tab, Tabs} from "@mui/material";
import {ToastContainer} from "react-toastify";
import {FileUpload} from "./LegacyScorecardsComponents/FileUpload.jsx";
import {Dags} from "./LegacyScorecardsComponents/Dags/Dags.jsx";
import {ReleaseScheduleCalendar} from "./LegacyScorecardsComponents/Calendar.jsx";
import {EmailNotification} from "./LegacyScorecardsComponents/EmailNotification";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{px: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const LegacyScorecards = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <ToastContainer/>
            <Page id={'period-release'} name={'Legacy Scorecards'}>
                <Box
                    sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
                >
                    <Tabs orientation="vertical" value={value} onChange={handleChange}>
                        <Tab label="File Upload" {...a11yProps(0)}/>
                        <Tab label="Pipelines" {...a11yProps(1)}/>
                        <Tab label="Calendar" {...a11yProps(2)}/>
                        <Tab label="Email" {...a11yProps(3)}/>
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <FileUpload/>
                    </TabPanel>
                    <TabPanel style={{flex: "1 1 auto"}} value={value} index={1}>
                        <Dags/>
                    </TabPanel>
                    <TabPanel style={{flex: "1 1 auto"}} value={value} index={2}>
                        <ReleaseScheduleCalendar/>
                    </TabPanel>
                    <TabPanel style={{flex: "1 1 auto"}} value={value} index={3}>
                        <EmailNotification/>
                    </TabPanel>
                </Box>

            </Page>
        </div>
    );
}