import '../../../../scss/CNS.scss';
import * as React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {SubscriptionForm} from "../SubscriptionForm.jsx";
import {
    DEFAULT_AGGREGATION_PERIODS,
    DEFAULT_DATA_GROUP,
    DEFAULT_DATA_GROUP_ID,
    PRODUCT_UNIVERSES
} from "../constants.js";


export const INITIAL_STATE = {
    dataGroup: DEFAULT_DATA_GROUP,
    dataGroupId: DEFAULT_DATA_GROUP_ID,
    productUniverses: PRODUCT_UNIVERSES,
    aggregationPeriods: DEFAULT_AGGREGATION_PERIODS,

};
export const CreateSubscription = () => {
    return (<SubscriptionForm initialState={INITIAL_STATE}/>)
}
