import axios from "axios";
import {toast} from "react-toastify";


export const getQueryFiles =  (subscriptionId) => {

    const auth0AccessToken = window.localStorage.getItem('access_token')

     axios.get(`${process.env.REACT_APP_RIS_API_URL}/cns/${subscriptionId}/queries`, {
        headers: {
            "Content-Type": "application/octet-stream",
            'authorization': `Bearer ${auth0AccessToken}`,

        },
         responseType: "blob"
    }).then(response => {
         const href = URL.createObjectURL(response.data);

         // create "a" HTML element with href to file & click
         const link = document.createElement('a');
         link.href = href;
         link.setAttribute('download', `${subscriptionId}.zip`); //or any other extension
         document.body.appendChild(link);
         link.click();

         // clean up "a" element & remove ObjectURL
         document.body.removeChild(link);
         URL.revokeObjectURL(href);
    }).catch(error => {
        if(error.message.includes(404)) {
            toast.error(`No queries found for ${subscriptionId}`);
        } else {
            console.log(`error - ${subscriptionId} : `, error);
        }
    });
}
