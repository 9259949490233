import { Button, CircularProgress, Grid, Table } from "@mui/material";
import { Dropdown } from "../../dropdown/Dropdown.jsx";

import "../../../scss/DataSubscription.scss";
import {
  useFetchCategories,
  useFetchGeoFamilyIds,
  useFetchMdmBrands,
  useFetchMdmCompanies,
  useFetchSubcategories,
  useFetchSubscriptions,
  useFetchDepartments,
  useListState,
} from "./DataSubscription.hooks.jsx";
import { useState, useEffect } from "react";
import { ComboBox } from "../../comboBox/ComboBox.jsx";
import { handleDelete, handleSave } from "./DataSubscription.handlers.js";
import { toast, ToastContainer } from "react-toastify";
import {generateSubscriptions} from "./utils.js";

const APPLICATIONS = [
  {
    value: "ignitePlus",
    displayName: "Application Filter (Ignite Plus)",
  },
  {
    value: "default",
    displayName: "Default Subscriptions",
  },
];

const PRODUCT_LEVELS = [
  {
    value: "DEPARTMENT",
    displayName: "Department",
  },
  {
    value: "CATEGORY",
    displayName: "Category",
  },
  {
    value: "SUBCATEGORY",
    displayName: "Subcategory",
  },
  {
    value: "BRAND",
    displayName: "Brand",
  },
  {
    value: "BRANDCAT",
    displayName: "Brand Category",
  },
  {
    value: "UPC",
    displayName: "UPC",
  },
];

export const DataSubscription = () => {
  const auth0Token = window.localStorage.getItem("access_token");

  // Data fetching states
  const { companyList, isCompaniesLoading } = useFetchMdmCompanies();
  const { brandList, isBrandListLoading } = useFetchMdmBrands();
  const { geoFamilyIds, isGeoFamilyIdsLoading } = useFetchGeoFamilyIds();
  const { subcategories, isSubcategoriesLoading } = useFetchSubcategories();
  const { categories, isCategoriesLoading } = useFetchCategories();
  const { departments, isDepartmentsLoading } = useFetchDepartments();


  // Selection States
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGeoFamilyIds, setSelectedGeoFamilyIds] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedProductLevel, setSelectedProductLevel] = useState([{
      value: "BRAND",
      displayName: "Brand",
  }]);
  const [selectedApplication, setSelectedApplication] = useState(
      APPLICATIONS[0].value
  );
  const {
    activeSubscriptions,
    fetchActiveSubscriptions,
    isActiveSubscriptionsLoading,
  } = useFetchSubscriptions(selectedCompany?.value, selectedApplication);
  const [isSaving, setIsSaving] = useState(false);

  const [
    subscriptions,
    addToSubscriptions,
    resetSubscriptions,
    removeSubscription,
  ] = useListState([]);

  useEffect(() => {
    if (selectedApplication === 'ignitePlus') {
      setSelectedProductLevel([{
        value: "BRAND",
        displayName: "Brand",
      }])
    }
  }, [selectedApplication])

  return (
    <div
      id="data-subscription"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "30px",
        padding: "10px",
        border: "solid",
      }}
    >
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          style={{ display: "flex", alignItems: "center", gap: "20px" }}
        >
          <span> Data Subscription </span>
          <Dropdown
            testId={"apps-dropdown"}
            label="Application"
            data={APPLICATIONS}
            currentValue={selectedApplication}
            onChange={(e) => {
              setSelectedApplication(e.target.value);
            }}
          />
        </Grid>

        <Grid item md={6}>
          {isCompaniesLoading ? (
            <CircularProgress />
          ) : (
            <ComboBox
              label="Company / Organization (SF ID)*"
              value={selectedCompany}
              isMultiple={false}
              disableCloseOnSelect={false}
              data={companyList}
              getOptionKeyImplementation={(option) => option.value}
              onChange={(event, value) => {
                setSelectedCompany(value);
              }}
            />
          )}
        </Grid>
        <Grid item md={3}>
          {isDepartmentsLoading ? (
              <CircularProgress />
          ) : (
              <ComboBox
                  label="Department"
                  value={selectedDepartment}
                  isMultiple={false}
                  disableCloseOnSelect={false}
                  data={departments}
                  getOptionKeyImplementation={(option) => option.value}
                  onChange={(event, value) => {
                    setSelectedDepartment(value);
                    setSelectedCategories(value.categories)
                    const subcategories = value.categories.flatMap(category => category.subcategories)
                    setSelectedSubcategories(subcategories)
                  }}
              />
          )}
        </Grid>
        <Grid item md={3}>
          {isBrandListLoading ? (
            <CircularProgress />
          ) : (
            <ComboBox
              label="Brand*"
              value={selectedBrands}
              isMultiple={true}
              disableCloseOnSelect={false}
              data={brandList}
              getOptionKeyImplementation={(option) => option.value}
              onChange={(event, value) => {
                setSelectedBrands(value);
              }}
            />
          )}
        </Grid>
        <Grid item md={6}>
          {isGeoFamilyIdsLoading ? (
            <CircularProgress />
          ) : (
            <ComboBox
              label="Geo Family ID*"
              value={selectedGeoFamilyIds}
              isMultiple={true}
              disableCloseOnSelect={true}
              data={geoFamilyIds}
              getOptionKeyImplementation={(option) => option.value}
              onChange={(event, value) => {
                setSelectedGeoFamilyIds(value);
              }}
            />
          )}
        </Grid>
        <Grid item md={3}>
          {isCategoriesLoading ? (
            <CircularProgress />
          ) : (
            <ComboBox
              label="Categories*"
              value={selectedCategories}
              isMultiple={true}
              disableCloseOnSelect={false}
              data={categories}
              getOptionKeyImplementation={(option) => option.value}
              onChange={(event, value) => {
                setSelectedCategories(value);
                const subcategories = value.flatMap(category => category.subcategories)
                setSelectedSubcategories(subcategories);
              }}
            />
          )}
        </Grid>
        <Grid item md={3}>
          {isSubcategoriesLoading ? (
              <CircularProgress />
          ) : (
              <ComboBox
                  label="Subcategories"
                  value={selectedSubcategories}
                  isMultiple={true}
                  disableCloseOnSelect={false}
                  data={subcategories}
                  getOptionKeyImplementation={(option) => option.value}
                  onChange={(event, value) => {
                    setSelectedSubcategories(value);
                  }}
              />
          )}
        </Grid>
        <Grid item md={6}>
          <ComboBox
              label="Product Level*"
              value={selectedProductLevel}
              testId={"product-level-multiselect"}
              isMultiple={true}
              disabled={selectedApplication === "ignitePlus"}
              disableCloseOnSelect={true}
              data={PRODUCT_LEVELS}
              getOptionKeyImplementation={(option) => option.value}
              onChange={(event, value) => {
                setSelectedProductLevel(value);
              }}
          />
        </Grid>
        <Grid
          item
          md={6}
          style={{ display: "flex", alignItems: "center", gap: "20px" }}
        >
          <Button
            className={"save-button"}
            variant="contained"
            disabled={
              selectedCompany === undefined ||
              selectedBrands.length === 0 ||
              selectedGeoFamilyIds.length === 0 ||
              selectedCategories === undefined
            }
            onClick={() => {
                const insertSubscriptions = generateSubscriptions(
                      selectedProductLevel,
                      [selectedDepartment],
                      selectedCategories,
                      selectedSubcategories
                    )
                    .map((subscription) => selectedGeoFamilyIds.map((geoFamilyId) => (
                        {
                          ...subscription,
                          geoFamilyId,
                          brands: selectedBrands,
                          company: selectedCompany,
                          enabled: true,
                        }
                    )))
                    .flat()
                    .filter((pendingSub) => {
                      const existInSubscriptions =
                          subscriptions.findIndex(
                              (subscription) => {
                                const pendingProductDescription = pendingSub.subcategory?.value ??
                                    pendingSub.category?.value ??
                                    pendingSub.department?.value
                                const subscriptionProductDescription = subscription.subcategory?.value ??
                                    subscription.category?.value ??
                                    subscription.department?.value
                                return subscription.geoFamilyId.value ===
                                pendingSub.geoFamilyId.value &&
                                    subscriptionProductDescription ===
                                    pendingProductDescription
                              }
                          ) >= 0;
                      const existInActiveSubscriptions =
                          activeSubscriptions.findIndex(
                              (subscription) => {
                                const productDescription = pendingSub.subcategory?.value ??
                                    pendingSub.category?.value ??
                                    pendingSub.department?.value
                                return subscription.sec_mkt_key ===
                                    pendingSub.geoFamilyId.displayName &&
                                    subscription.sec_prd_key.includes(
                                        productDescription
                                    )
                              }

                          ) >= 0;

                      return !(existInSubscriptions || existInActiveSubscriptions);
                    });
                if (insertSubscriptions.length < selectedGeoFamilyIds.length) {
                  toast.warning("Some of the current selections already exist.");
                }
                addToSubscriptions(insertSubscriptions);
            }}
          >
            INSERT
          </Button>

          <Button
            className={"save-button"}
            variant="outlined"
            onClick={resetSubscriptions}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
      <hr />
      <div style={{ width: "100%" }}>
        <p>Preview/Edit</p>
        <p>Company/Organization: {selectedCompany?.displayName}</p>
        <h5>Subscriptions:</h5>
        <Table>
          <thead>
            <tr>
              <th>S. No</th>
              <th>Geo Family ID</th>
              <th>Product Description</th>
              <th>Product Level</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.length > 0 && <h6>Pending Additions</h6>}
            {subscriptions.map((subscription, index) => (
              <tr key={index}>
                <td></td>
                <td>{subscription.geoFamilyId?.displayName}</td>
                <td>{subscription.subcategory?.displayName ??
                    subscription.category?.displayName ??
                    subscription.department?.displayName}</td>
                <td>{subscription.productLevel?.displayName}</td>
                <td
                  onClick={() => removeSubscription(index)}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {" "}
                  DELETE
                </td>
              </tr>
            ))}
            {activeSubscriptions?.length > 0 && <h6>Current Subscriptions</h6>}
            {isActiveSubscriptionsLoading && <CircularProgress />}
            {!isActiveSubscriptionsLoading &&
              activeSubscriptions?.map((subscription) => (
                <tr key={`active-subcription-${subscription.id}`}>
                  <td>{subscription.id}</td>
                  <td>{subscription.sec_mkt_key}</td>
                  <td>
                    {subcategories.find(
                      (subcategory) =>
                        subcategory.value ===
                        subscription.sec_prd_key.split("|")[1]
                    )?.displayName || subscription.sec_prd_key.split("|")[1]}
                  </td>
                  <td>{subscription.prd_lvl}</td>
                  <td
                    onClick={() =>
                      handleDelete(subscription.id, selectedApplication, auth0Token).then(() =>
                        fetchActiveSubscriptions()
                      )
                    }
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    DELETE
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={"save-button"}
            variant="outlined"
            disabled={subscriptions.length === 0 || isSaving}
            onClick={() => {
              setIsSaving(true);
              selectedCompany &&
                handleSave(
                  {
                    companyId: selectedCompany.value,
                    application: selectedApplication,
                    subscriptions,
                  },
                  auth0Token,
                  setIsSaving
                ).then(() => {
                  fetchActiveSubscriptions();
                  resetSubscriptions();
                });
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  );
};
