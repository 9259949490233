export const ACCOUNTS_COMPANY_QUERY = `
    query($page: Int!, $pageSize: Int!) {
        data: listCompanies(pageable: {page: $page, pageSize: $pageSize}) {
            data {
                destiniClientId
                name
                satoriOrganizationId
                uuid
                companyId
                accountUuid
            }                      
            page {
                totalItems
                totalPages
                currentPage
            }
        }
    }
`

export const CURRENT_PERIOD_QUERY = `
    query {
        data: sdnDeliveryShiningLanding763StandardRetailMetadataDimPeriodsCurrentPeriod {
            abbreviation
        }
    }
`

export const CONTRACT_BY_ACCOUNT = `
    query ($accountUuid: String) {
        data: getContract(accountUuid: $accountUuid) {
            id
            startDate
            endDate
            uuid
            contractNumber
            accountSubscriptions {
                id
                subscription {
                    uuid
                    id
                    name
                }
            }
        }
    }
`