export const PRODUCT_UNIVERSES = [
    {
        displayName: "NPI",
        value: "NPI"
    },
    {
        displayName: "HWI",
        value: "HWI"
    },
    {
        displayName: "TPL",
        value: "TPL"
    },
];

export const DEFAULT_AGGREGATION_PERIODS =[
    {
        displayName: "4 WEEKS",
        value: "4 WEEKS"
    },
    {
        displayName: "12 WEEKS",
        value: "12 WEEKS"
    },
    {
        displayName: "24 WEEKS",
        value: "24 WEEKS"
    },
    {
        displayName: "52 WEEKS",
        value: "52 WEEKS"
    }
]

export const AGGREGATION_PERIODS = [
    ...DEFAULT_AGGREGATION_PERIODS,
    {
        displayName: "YTD",
        value: "YTD"
    },
];
export const REPORT_TYPE = [
    {
        displayName: "Base",
        value: "Base"
    },
    {
        displayName: "Lite",
        value: "Lite"
    }
];
export const CUSTOM_SLICERS = [
    {
        displayName: "None",
        value: " "
    },
    {
        displayName: "Geography Type=1; Custom Type=2",
        value: "Geography Type=1; Custom Type=2"
    }
];

export const ALL_BRANDS = [
    {
        displayName: "ALL BRANDS",
        value: "ALL_BRANDS"
    }
]
export const RUN_QUERY = `mutation($id: String! $createExcel: Boolean) {
data:createPowertabsFromSubscriptionId(id: $id createExcel: $createExcel) {
    dataLocation
    dataAccessMetadata {
      fileName
      rowCount
    }
}
}`
