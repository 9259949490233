import axios from "axios";
import {isTokenExpired} from "../components/auth/accessTokenValidator.js";
import JwtDecode from "jwt-decode";

export const getAuth0ApiAccessToken = async () => {
    const token = window.localStorage.getItem('auth0_api_access_token')
    const payload = {
        'grant_type': "client_credentials",
        'client_id': process.env.REACT_APP_SDN_OFFICE_AUTH_CLIENT_ID,
        'client_secret': process.env.REACT_APP_SDN_OFFICE_AUTH_CLIENT_SECRET,
        'audience': `${process.env.REACT_APP_AUTH_URL}/api/v2/`
    }
    if(!token || isTokenExpired(JwtDecode(token))) {
        const api_token = await axios.post(`${process.env.REACT_APP_AUTH_URL}/oauth/token`, payload)
        window.localStorage.setItem('auth0_api_access_token', api_token.data.access_token)
    }
}