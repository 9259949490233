export const MARKETS_RETAIL_ACCOUNTS_ERROR = "Either markets or retails accounts is required.";

const ARRAY_FIELDS_TO_CHECK = [
    "categories",
    "toEmail",
    "clientBrands",
    "competitorBrands",
    "aggregateProductUniverses",
    "aggregationPeriods",
]

const STRING_FIELDS_TO_CHECK = [
    "filePreFixName",
    "poshtaBrandCode"
]

export const REQUIRED = "Required.";
export const SUBCATEGORY_LIMIT = 60;
export const validate = (values) => {
    let errors = {};
    if (!values?.markets?.length && !values?.retail_accounts?.length) {
        errors.markets = MARKETS_RETAIL_ACCOUNTS_ERROR
        errors.retail_accounts = MARKETS_RETAIL_ACCOUNTS_ERROR
    }

    if (values?.subcategories?.length > SUBCATEGORY_LIMIT) {
        errors.subcategories = `Subcategory limit is ${SUBCATEGORY_LIMIT}.`
    }

    STRING_FIELDS_TO_CHECK.forEach((field) => {
        if (!values[field]) {
            errors[field] = REQUIRED
        }
    })

    ARRAY_FIELDS_TO_CHECK.forEach((field) => {
        if (!values[field]?.length) {
            errors[field] = REQUIRED
        }
    })

    return errors;
};
