import React, {Component} from 'react';
import '../scss/App.scss';
import {Route, Switch} from "react-router-dom";
import {RouteWithAuthorization} from "./auth/RouteWithAuthorization.jsx";
import $ from 'jquery';
import 'foundation-sites';
import {ROUTES} from "../routes.js";
import {Login, LoginCallback, Logout, Unauthorized} from "@spins-gcp/auth0-react";

class Dashboard extends Component {

    async componentDidMount() {
        $(document).foundation();
    }

    render() {
        return (
            <>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/loginCallback" component={LoginCallback}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/unauthorized" component={Unauthorized}/>
                    {
                        ROUTES.map(({path, component}) =>
                            <RouteWithAuthorization exact
                                                    key={path}
                                                    path={path}
                                                    component={component}
                                                    routes={ROUTES}
                            />)
                    }
                </Switch>
            </>);
    }
}

export default Dashboard;
