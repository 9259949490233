import axios from "axios";
import {toast} from "react-toastify";

const PAUSE_DAG_API = (dag_id) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/dags/pause?dag_id=${dag_id}`;
const TRIGGER_SCORECARDS_DAG_API = (dag_id) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/dags/trigger?dag_id=${dag_id}`;
export const handleScorecardsTrigger = async (dagId, auth0AccessToken) => {
    return axios.post(TRIGGER_SCORECARDS_DAG_API(dagId), {
        conf: {}
    }, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${auth0AccessToken}`
        },
    }).then((response) => {
        if (response.status === 200) {
            toast.success(`Successfully triggered ${dagId}`)
        } else {
            toast.error(`Unsuccessful Operation: ${response.status} ${response.statusText}`)
        }
    }).catch((e) => {
        toast.error(e)
    })
}
export const handlePaused = async (dagId, state, auth0AccessToken, cb) => {
    return axios.put(PAUSE_DAG_API(dagId), {
        pause_state: !state
    }, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${auth0AccessToken}`
        },
    }).then((response) => {
        if (response.status === 200) {
            toast.success(`Successfully ${state ? "unpaused" : "paused"} ${dagId}`)
            cb(response.data)

        } else {
            toast.error(`Unsuccessful Operation: ${response.status} ${response.statusText}`)
        }
    }).catch((e) => {
        toast.error(e)
    })
}
