import {useEffect, useState} from "react";
import axios from "../../../api/axiosApiClient.js";
import {REQUEST_ENDPOINT} from "./MidMarket.constant.js";
import {toast} from "react-toastify";

async function fetchGlobalEmailList(){
    return axios.get(`${REQUEST_ENDPOINT}/retailer/global-email-config`)
}
async function updateGlobalEmailList(newList){
    return axios.post(`${REQUEST_ENDPOINT}/retailer/update-global-email-config`,  newList)
}
export function useGlobalEmailRecipients() {
    const [recipients, setRecipients] = useState([]);
    useEffect(() => {
        fetchGlobalEmailList().then(({data}) => {
            setRecipients(data)
        })
    }, []);
    const updateRecipientHandler = (value) => {
        setRecipients(value);
        updateGlobalEmailList(value).then(({data}) => toast.success(`Updated email list to: ${data.join(', ')}`))
    }
    return {recipients, setRecipients, updateRecipientHandler}
}