import axios from 'axios'

const auth0AccessToken = window.localStorage.getItem("access_token")
const instance = axios.create({
    headers: {
        'authorization': `Bearer ${auth0AccessToken}`,
        'Content-Type': 'application/json'
    }
});

export default instance