export const SDNO_INTERNAL_USERS = `
    query sdnoInternalUsers($companyId: String) {
      sdnoInternalUsers(companyId: $companyId, isActive: true, first: 2000) {
        edges {
          node {
            email
            id
            fname
            lname
          }
        }
      }
    }
`