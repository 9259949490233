import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Button, IconButton, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {makeApolloClientCall as nirvanaApolloClientCall} from "../../../helpers/queryNirvanaCoreApi.js";
import {CREATE_COMPANY_MUTATION} from "./mutation.js";
import {toast} from "react-toastify";

export const NewCompanyModal = ({isOpen, handleClose, loadCompanies, handleLogAction}) => {
    const [newCompany, setNewCompany] = useState({
        name: "",
        companyUniqueId: "",
    });

    const handleInput = (name) => {
        setNewCompany({
            name: name,
            companyUniqueId: name.replaceAll(" ", "").toLowerCase(),
        });
    };

    const clearCompanyField = () => {
        setNewCompany({name: "", companyUniqueId: ""});
    };

    const handleSubmit = () => {
        nirvanaApolloClientCall(CREATE_COMPANY_MUTATION, false, {
            input: newCompany,
        })
            .then((res) => {
                handleLogAction({...newCompany, companyUuid: res?.createCompany?.uuid});
                loadCompanies().then();
                toast.success(
                    `New Company ${res?.createCompany?.uuid} successfully saved.`
                );
            })
            .catch((error) => {
                toast.error(
                    `An error occurred while saving the subscription. ${error}`
                );
            })
            .finally(() => {
                handleClose();
                clearCompanyField();
            });
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
        >
            <div className={"account-dialog-title"}>
                <DialogTitle>Add Company</DialogTitle>
                <IconButton
                    onClick={() => {
                        handleClose();
                        clearCompanyField();
                    }}
                    // className={"button-close"}
                    test-id={"contract-modal-close-btn"}
                >
                    <CloseIcon/>
                </IconButton>
            </div>
            <DialogContent sx={{padding: '0px 24px'}}>
                <div style={{
                    gap: '10px',
                    display: 'grid'
                }}>
                    <div style={{display: "flex"}}>
                        <TextField
                            label={"Company Name"}
                            variant="outlined"
                            fullWidth={true}
                            value={newCompany.name}
                            onChange={(event) => {
                                handleInput(event.target.value);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                        }}
                    >
                        <TextField
                            label={"Company Unique ID"}
                            variant="outlined"
                            fullWidth={true}
                            value={newCompany.companyUniqueId}
                            onChange={(event) =>
                                setNewCompany({
                                    ...newCompany,
                                    companyUniqueId: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{margin: '5px 15px'}}>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleClose();
                        clearCompanyField();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!newCompany.companyUniqueId || !newCompany.name}
                    variant="contained"
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
