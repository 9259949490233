import React, { useRef} from "react";
import {Box, TextField} from "@mui/material";
import {debounce} from "lodash";


export function sendDestiniSession(iframe, clientId){
    const mockSession = {
        destiniOrganization: {
            name: clientId
        },
    }

    iframe.current.contentWindow.postMessage(JSON.stringify(mockSession), '*');
}

export function sendSatoriSession(iframe, clientId) {
    const mockSession = {
        homeOrganization: {
            id: clientId,
        },
    }

    iframe.current.contentWindow.postMessage(JSON.stringify(mockSession), '*');
}

export const IframeTester = ({clientId, showControls = true, height="768", width="1024"}) => {
    const iframeRef = useRef(null)

    const handleIdChange = (e) => {
        const value = +e.target.value
        if (isNaN(value)) {
            sendDestiniSession(iframeRef, e.target.value)
        } else {
            sendSatoriSession(iframeRef, value)
        }
    }


    return (
        <Box margin={4} data-testid={'iframe-tester-component'}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                {
                    showControls && (
                        <TextField sx={{margin: '8px'}} id="outlined-basic" label="Client ID" helperText="Assumes numeric input as Satori, otherwise assumes Signals/Destini" variant="outlined" size="small" value={clientId} onKeyUp={debounce(handleIdChange, 500)} data-testid={'iframe-controls'}/>
                    )
                }
                <iframe ref={iframeRef} id="scorecardFrame" onLoad={() => clientId && handleIdChange({target: {value: clientId}})} src="https://satoriscorecards.spins.com/" style={{margin: '16px'}} height={height} width={width} sandbox="allow-scripts allow-same-origin" data-testid={'iframe-element'}></iframe>
            </Box>
        </Box>

    )
}

