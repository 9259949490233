import {Formik, Field, Form} from "formik";
import {Button} from "@mui/material";
import {query} from "../../../helpers/queryNirvanaApi.js";
import React, {useEffect, useState} from "react";
import {Table} from "../../tables/Table.jsx";
import {toast, ToastContainer} from "react-toastify";

const subscriptionMap = {
    'Conventional': 'Spins KAS',
    'Exclusive': 'Signals KAS',
    'Albertsons': 'Albertsons',
    'NOSHE': 'NOSHE'
}

const COLUMNS = [
    {
        Header: 'Company',
        accessor: 'company_name'
    },
    {
        Header: 'Contract UUID',
        accessor: 'contract_uuid'
    },
    {
        Header: 'Contract #',
        accessor: 'contract_number'
    },
    {
        Header: 'Start Date',
        accessor: 'start_date'
    },
    {
        Header: 'End Date',
        accessor: 'end_date'
    },
    {
        Header: 'Created At',
        accessor: 'created_at'
    },
    {
        Header: 'Last Update',
        accessor: 'last_update'
    },
]

const getSubscriptionId = async (name) => {
    return await query(`query { subscriptionIds(name: "${name}") { uuid } }`)
}

const insertSubscription = async (contract_uuid, subscription_uuid) => {
    return await query(`mutation {
            insertSubscription(
                subscription: {
                    subscription_uuid: "${subscription_uuid}",
                    contract_uuid: "${contract_uuid}"
                }
            )
        }`)
}

const createSubscription = async (values, setSubmitting) => {
    setSubmitting(true)
    const {contract_uuid, subscription} = values

    const subscription_name = subscriptionMap[subscription]
    const subscription_uuid_result = await getSubscriptionId(subscription_name)

    const subscription_uuid = subscription_uuid_result.data.subscriptionIds[0].uuid

    const subscription_result = await insertSubscription(contract_uuid, subscription_uuid)

    if (subscription_result.errors) {
        toast.error('Error, try again')
    } else if (JSON.parse(subscription_result.data.insertSubscription).sqlStatementResults[0].numberOfRecordsUpdated) {
        toast.success('Subscription created')
    } else {
        toast.error('Error, try again')
    }

    setSubmitting(false)
}

export const CreateSubscription = () => {
    const [contracts, setContracts] = useState([])

    useEffect(() => {
        query(`query { contracts { contract_uuid company_name contract_number start_date end_date created_at last_update } }`)
            .then((data) => {
                if (data.errors || !data.data.contracts) {
                    setContracts([{'company_name': 'Please Refresh Page', 'contract_uuid': 'Data not fetched'}])
                } else {
                    setContracts(data.data.contracts)
                }
            })
    }, [])

    return (
        <div className='page-wrapper'>
            <ToastContainer/>
            <div className='form'>
                <h1>Create Scorecards Subscription</h1>
                <Formik
                    initialValues={{
                        contract_uuid: '',
                        subscription: 'Conventional',
                        noshe: false
                    }}
                    onSubmit={async (values, {setSubmitting}) => {
                        await createSubscription(values, setSubmitting)
                    }}
                >
                    {({
                          isSubmitting
                      }) => (
                        <Form>
                            <div>
                                <label>Contract UUID</label>
                                <Field name="contract_uuid" aria-label="contract_uuid" type="text"/>
                            </div>

                            <label>Subscription</label>
                            <Field name="subscription" aria-label="subscription" as="select">
                                <option value="Conventional">Conventional (Spins KAS)</option>
                                <option value="Exclusive">Exclusive (Signals KAS)</option>
                                <option value="Albertsons">Albertsons</option>
                                <option value="NOSHE">NOSHE</option>
                            </Field>

                            <Button variant="outlined" type="submit" aria-label="create-subscription"
                                    disabled={isSubmitting}>Create</Button>

                            <div style={{"margin": "10px"}}>
                                If you wish to create a subscription for “All” - you need to put in two separate entries,
                                one for each of Conventional and Exclusive, for that Contract UUID.
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className='table-container' style={{"width": "50%"}}>
                <Table columns={COLUMNS} data={contracts}/>
            </div>
        </div>
    )
}