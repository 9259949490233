import { toast } from "react-toastify";
import axios from "../../../api/axiosApiClient.js";

const TRIGGER_MIDMARKET_DAG_API = (dag_id) =>
  `${process.env.REACT_APP_RIS_API_URL}/mid-market-report/dags/trigger?dag_id=${dag_id}`;

export const handleMidmarketTrigger = async (conf) => {
  return axios
    .post(
      TRIGGER_MIDMARKET_DAG_API('mid_market_report'),
      {
        conf
      }
    )
    .then((response) => {
      if (response.status === 200) {
        toast.success(`Successfully triggered MidMarketReportDAG`);
      } else {
        toast.error(
          `Unsuccessful Operation: ${response.status} ${response.statusText}`
        );
      }
    })
    .catch((e) => {
      toast.error(e.message);
    });
};
