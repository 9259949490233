import React, {useEffect, useState} from 'react';
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import Page from "../../Page.jsx";
import SatoriExtractCreatedDate from "../../display/SatoriExtractCreatedDate.jsx";
import {Table} from "../../tables/Table.jsx";

const COLUMNS = [
    {
        Header: 'GeoFamilyId',
        accessor: 'geoFamilyId'
    },
    {
        Header: 'Description',
        accessor: 'geoFamilyDescription'
    },
    {
        Header: 'Subscription Type',
        accessor: 'subscriptionType'
    },
    {
        Header: 'Market List',
        accessor: 'marketList',
        Cell: (props) => {
            const marketKeys = props.row.original?.marketList || []
            return <div>{marketKeys.join(', ')}</div>
        },
        maxWidth: 300,
        width: 50,
        disableFilters: true
    }
]

const GeoFamily = () => {
    const [geoFamilyIds, setGeoFamilyIds] = useState([])
    const [geoFamilyDataIsLoading, setGeoFamilyDataIsLoading] = useState(true)
    const [createdDate, setCreatedDate] = useState('Unknown')

    const geoFamilyIdsQuery = `query GeoFamilyIds {
    geoFamilyIds {
        createdDate
        geoFamilyId {
            geoFamilyId
            marketList
            geoFamilyDescription
            subscriptionType
            }
        }
    }`

    useEffect(() => {
        makeApolloClientCall(geoFamilyIdsQuery)
            .then((data) => {
                setCreatedDate(data.geoFamilyIds?.createdDate ?? 'Unknown')
                setGeoFamilyIds(data.geoFamilyIds?.geoFamilyId ?? [])
                setGeoFamilyDataIsLoading(false)
            })
    }, [geoFamilyIdsQuery])
    return geoFamilyDataIsLoading ? (<div>Loading...</div>) :
        (
            <Page name='GeoFamilyIds'>
                <div className={'table-container'}>
                    <div className={'data-table'}>
                        <SatoriExtractCreatedDate createdDate={createdDate}/>
                        <Table columns={COLUMNS} data={geoFamilyIds}/>
                    </div>
                </div>
            </Page>
        )
};

export default GeoFamily;