import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const ItemStatusIndicator = ({text, isCompleted = null}) => {
    return (
        isCompleted !== null
            ? <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                <CheckCircleIcon
                    style={{
                        color: isCompleted ? "green" : "grey",
                        fontSize: "20px",
                        marginRight: "10px",
                    }}
                />
                <span>{text}</span>
            </div>
            : <></>
    )
}