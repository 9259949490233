import {DEFAULT_CATEGORIES, DEFAULT_DEPARTMENT, DEFAULT_SUBCATEGORIES} from "./constants.js";

export function setForm(initialState) {
    return {
        filePrefixName: initialState.filePrefixName ?? "",
        companies: initialState.companies ?? [],
        brands: initialState.brands ?? [],
        departments: initialState.departments?.length > 0 ? initialState.departments : [DEFAULT_DEPARTMENT],
        categories: initialState.categories?.length > 0 ? initialState.categories : [DEFAULT_CATEGORIES],
        subcategories: initialState.subcategories?.length > 0 ? initialState.subcategories : [DEFAULT_SUBCATEGORIES],
        toEmailRecipients: initialState.toEmailRecipients ?? [],
        ccEmailRecipients: initialState.ccEmailRecipients ?? [],
        dataGroup: initialState.dataGroup,
        dataGroupId: initialState.dataGroupId,
        productUniverses: initialState.productUniverses,
        aggregationPeriods: initialState.aggregationPeriods,
    };
}
