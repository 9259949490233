import {toast, ToastContainer} from "react-toastify";
import {VM_INSTANCE_QUERY} from "./queries.js";
import {DELETE_VM_INSTANCE} from "./mutations.js";
import React, {useEffect, useState} from "react";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {Box, Button, CircularProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {CustomNoRowsOverlay} from "../common/components/CustomNoRowsOverlay.js";
import Page from "../../Page.jsx";

export const VMInstances = () => {
    const [instances, setInstances] = useState([])
    const [selectedInstances, setSelectedInstances] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const COLUMNS = [
        {headerName: "Name", field: 'name', flex: 1,},
        {headerName: "Creation Date", field: 'createDate', flex: 1,},
        {headerName: "Status", field: 'status', flex: 1,},
    ]

    const fetchVmInstances = () => {
        return makeApolloClientCall(VM_INSTANCE_QUERY, false, {})
    }

    useEffect(() => {
        fetchVmInstances()
            .then((graphqlData) => {
                setInstances(graphqlData.data)
            })
            .finally(() => setIsLoading(false))
    }, [])

    const handleDelete = (vmNames) => {
        setIsLoading(true)
        makeApolloClientCall(DELETE_VM_INSTANCE, false, {vmNames: vmNames})
            .then(async () => {
                toast.success("Delete Success!");
            })
            .catch(error => toast.error(`${error}`))
            .finally(async () => {
                await delay(2000);
                const updated = await fetchVmInstances()
                setInstances(updated.data)
                setIsLoading(false)
            })
    }

    return (
        <Page name={"VM Instances"}>
            <div>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <ToastContainer autoClose={false}/>

                    {!isLoading ? (
                        <DataGrid
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            columns={COLUMNS}
                            rows={instances}
                            getRowId={(row) => row.name}
                            checkboxSelection
                            loading={isLoading}
                            rowHeight={40}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setSelectedInstances(newRowSelectionModel);
                            }}
                            rowSelectionModel={selectedInstances}
                            isRowSelectable={(params) => params.row.status !== 'STOPPING'}
                            testId="vm-instances-table"
                        />
                    ) : (
                        <CircularProgress sx={{position: "relative", left: "50%"}} data-testid={"main-loader"}/>
                    )}

                    <Button
                        disabled={selectedInstances.length < 1}
                        style={{
                            marginTop: "1em",
                            width: "100px"
                        }}
                        variant="contained"
                        onClick={async (e) => {
                            e.preventDefault();
                            if (selectedInstances) {
                                handleDelete(selectedInstances)
                            }
                        }}
                        color="error"
                    >
                        Delete
                    </Button>
                </Box>
            </div>
        </Page>
    )
}