import PropTypes from "prop-types";
import BaseModal from "../modals/BaseModal.jsx";

const StoreInsightsCellContainer = ({row}) => {
    const retailTags = row.retailTags || '';
    const brands = row.brands || ''
    return (
        <div className={'permission-cell-container'}>
            {retailTags ? (
                <div className={'modal-margin-right'}>
                    <BaseModal children={retailTags?.split(',').join(', ')}
                               openButtonTitle={'Retail Tags'}
                               headerText={'Retail Tags'}
                               buttonClass={'green-button'}
                    />
                </div>
            ) : <></>}
            {brands ? (
                <BaseModal children={brands?.split(',').join(', ')}
                           openButtonTitle={'Brands'}
                           headerText={'Brands'}
                           buttonClass={'green-button'}
                />
            ) : <></>}
        </div>
    )
};

StoreInsightsCellContainer.propTypes = {
    row: PropTypes.object
};

export default StoreInsightsCellContainer;