import {query} from "../../../helpers/queryNirvanaApi.js";


export async function loadNewMarketKeys(data) {
    if (!data.length)
        return

    const initialValue = `(${data[0]['current_market_key']},${data[0]['new_market_key']},'${data[0]['current_market_name']}','${data[0]['new_market_name']}')`

    const newMarketKeyTuples = data.slice(1).reduce(
        (prev, current) => prev + ',' + `(${current['current_market_key']},${current['new_market_key']},'${current['current_market_name']}','${current['new_market_name']}')`,
        initialValue
    )

    return await query(
        `mutation {
            loadNewMarketKeys(
                market_key_tuples: "${newMarketKeyTuples}"
            )
        }`
    ).then(async (result) => {
        if (!result) {
            throw Error('Error loading market keys')
        }

        return await query(
            `query {
                newMarketKeys {
                    current_market_key
                    new_market_key
                    current_market_name
                    new_market_name
                }
            }`)
    })
}