import {Chip} from "@mui/material";
import React from "react";

// Similar to Dag Id list in composer component
export const DAG_IDS = [
    {
        dagId: "datascience-workspace-vendorscorecard.vendorscorecard-pipeline",
        display: "Vendor Scorecard",
        url: "https://a283622d437d4c3faf39f4e008759a31-dot-us-central1.composer.googleusercontent.com/dags/datascience-workspace-vendorscorecard.vendorscorecard-pipeline/grid",
        webserver: "data-science"
    },
    {
        dagId: "datascience-workspaces-sprouts-business-review-scorecard.brs",
        display: "Sprouts Business Review Scorecard",
        url: "https://a283622d437d4c3faf39f4e008759a31-dot-us-central1.composer.googleusercontent.com/dags/datascience-workspaces-sprouts-business-review-scorecard.brs/grid",
        webserver: "data-science"
    },
    {
        dagId: "datascience-workspace-ncg-retailerdashboard.retailer-dashboard",
        display: "NCG Retailer Dashboard",
        url: "https://a283622d437d4c3faf39f4e008759a31-dot-us-central1.composer.googleusercontent.com/dags/datascience-workspace-ncg-retailerdashboard.retailer-dashboard/grid",
        webserver: "data-science"
    },
    {
        dagId: "scorecards_jbp",
        display: "JBP Scorecards Generation",
        url: "https://b43f3d1148834260b322cdd98d86d0f0-dot-us-central1.composer.googleusercontent.com/dags/scorecards_jbp/grid",
        webserver: "ris-orchestration"
    },
    {
        dagId: "scorecards_noshe",
        display: "Noshe Scorecards Generation",
        url: "https://b43f3d1148834260b322cdd98d86d0f0-dot-us-central1.composer.googleusercontent.com/dags/scorecards_noshe/grid",
        webserver: "ris-orchestration"
    },
    {
        dagId: "scorecards_promotion",
        display: "Promotion Scorecards Generation",
        url: "https://b43f3d1148834260b322cdd98d86d0f0-dot-us-central1.composer.googleusercontent.com/dags/scorecards_promotion/grid",
        webserver: "ris-orchestration"
    },
]
export const STATE_MAPPER = {
    "queued": "default",
    "running": "info",
    "success": "success",
    "failed": "error"
}
export const dateToLocaleString = ({value}) => new Date(value).toLocaleString()

export const COLUMNS = [
    {
        field: "state",
        headerName: "State",
        renderCell: ({value}) => <Chip color={STATE_MAPPER[value]} label={value} clickable={false}/>
    },
    {field: "dag_run_id", headerName: "Run ID", minWidth: 300},
    {
        field: "execution_date",
        headerName: "Execution Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {
        field: "start_date",
        headerName: "Start Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {
        field: "end_date",
        headerName: "End Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {field: "run_type", headerName: "Run Type"},
    {field: "external_trigger", headerName: "External?"},
]
