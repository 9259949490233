import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Paper,
	Typography,
} from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Dropdown } from "../../dropdown/Dropdown.jsx";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { makeApolloClientCall } from "../../../api/makeApolloApiCall.js";
import { CURRENT_PERIOD_QUERY } from "../noshe/queries.js";
import { AuditLogTable } from "../../auditLogTable/AuditLogTable.jsx";
import {createAuditLogEntry, RI_AUDIT_LOG_API} from "../../../helpers/createAuditLogEntry.js";

const PARAMETER_NAMES = [
	`ilab-resources-scheduler-data-stats`,
	`ilab-release-execution-data-stage`,
].map((p) => (process.env.REACT_APP_ENV === "prod" ? p : p + `-dev`));

const RELEASE_EXEC_PARAMETER_NAME = PARAMETER_NAMES[1];
const REQUEST_ENDPOINT = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/parameters`;

const RESOURCE_SCHEDULER_DEFAULT_VALUE = {
	airflow_dag: {
		available_spots: 20,
		spots_usage: {},
	},
	data_test_suite: {
		available_spots: 5,
		spots_usage: {},
	},
	glue_job: {
		available_spots: 10,
		spots_usage: {},
	},
	glue_workflow: {
		available_spots: 5,
		spots_usage: {},
	},
	state_machine: {
		available_spots: 6,
		spots_usage: {},
	},
};

async function getLastPeriod(data) {
	// Get previous period
	const currentPeriod = (
		await makeApolloClientCall(CURRENT_PERIOD_QUERY, false, {})
	).data.abbreviation;
	const period = (
		((((parseInt(currentPeriod.substring(6)) - 2) % 13) + 13) % 13) +
		1
	)
		.toString()
		.padStart(2, "0");
	const currentYear = parseInt(currentPeriod.substring(0, 4));
	const year = (period === "13" ? currentYear - 1 : currentYear).toString();
	const previousPeriod = `${year}${period}`;
	// Get 28 days ago
	const last28Days = moment().subtract(4, "weeks").format("YYYY-MM-DD");

	return {
		...data,
		period_release: {
			...data.period_release,
			last_execution_date: last28Days,
			period_number: previousPeriod,
		},
		innovation_database_update: {
			...data.innovation_database_update,
			last_execution_date: last28Days,
			period_number: previousPeriod,
		},
	};
}

export const AwsParameterStore = () => {
	const [isEdit, setIsEdit] = useState(false);
	const [currentSelection, setCurrentSelection] = useState(
		PARAMETER_NAMES[0]
	);
	const [jsonData, setJsonData] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = React.useState(false);

	const auth0AccessToken = window.localStorage.getItem("access_token");
	const pre = document.querySelector(`pre`);

	const fetchParameter = useCallback(
		(currentSelection) => {
			const abortController = new AbortController();
			return axios.get(`${REQUEST_ENDPOINT}/${currentSelection}`, {
				headers: {
					authorization: `Bearer ${auth0AccessToken}`,
				},
				signal: abortController.signal,
			});
		},
		[auth0AccessToken]
	);

	useEffect(() => {
		setIsLoading(true);
		fetchParameter(currentSelection)
			.then(({ data }) => {
				setJsonData(data);
			})
			.catch((e) => toast.error(e))
			.finally(() => {
				setIsLoading(false);
				setIsEdit(false);
			});
	}, [currentSelection]);

	const handleReset = (resetVal) => {
		document.querySelector(`pre`).innerHTML = JSON.stringify(
			resetVal,
			undefined,
			2
		);
	};

	const handleUpdate = () => {
		setIsLoading(true);
		setJsonData(JSON.parse(pre.textContent));
		axios
			.post(
				`${REQUEST_ENDPOINT}/${currentSelection}`,
				{ value: pre.textContent },
				{ headers: { authorization: `Bearer ${auth0AccessToken}` } }
			)
			.then(async () => {
				toast.success("Save successful!");
				setIsEdit(false);
				await fetchParameter(currentSelection);
				await createAuditLogEntry(RI_AUDIT_LOG_API, {
					parameter: currentSelection,
					payload: JSON.parse(pre.textContent),
				});
			})
			.catch((error) => {
				toast.error(`${error}`);
				return false;
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	return (
		<>
			<ToastContainer />

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					width: "90%",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<Typography
					variant={"h4"}
					sx={{ marginTop: "8px", marginBottom: "16px" }}
				>
					AWS Parameter Store
				</Typography>
				<Dropdown
					label={`Parameter Name`}
					data={PARAMETER_NAMES.map((name) => {
						return {
							value: name,
							displayName: name,
						};
					})}
					sx={{
						marginBottom: "16px",
						width: "100%",
						maxWidth: "1080px",
					}}
					currentValue={currentSelection}
					onChange={(e) => setCurrentSelection(e.target.value)}
					testId={"parameter-dropdown"}
				/>
				<div
					style={{
						width: "100%",
						display: "flex",
						maxWidth: "1080px",
					}}
				>
					{isLoading ? (
						<CircularProgress
							sx={{ position: "relative", left: "50%" }}
							data-testid={"main-loader"}
						/>
					) : (
						<>
							<Paper
								sx={{
									backgroundColor: "#f1f1f1",
									width: "80%",
								}}
							>
								<pre
									contentEditable={isEdit}
									style={{
										color: !isEdit ? "#767676" : "initial",
									}}
								>
									{JSON.stringify(jsonData, undefined, 2)}
								</pre>
							</Paper>
							<div
								style={{
									width: "20%",
									display: "flex",
									flexDirection: "column",
									gap: "8px",
								}}
							>
								<Button
									style={{ marginLeft: 25 }}
									variant="contained"
									onClick={(e) => {
										e.preventDefault();
										setIsEdit(!isEdit);
									}}
								>
									EDIT
								</Button>
								{currentSelection ===
								RELEASE_EXEC_PARAMETER_NAME ? (
									<Button
										style={{ marginLeft: 25 }}
										variant="contained"
										onClick={async (e) => {
											e.preventDefault();
											setJsonData(
												await getLastPeriod(jsonData)
											);
											handleReset(jsonData);
										}}
									>
										28 DAYS AGO
									</Button>
								) : (
									<Button
										style={{ marginLeft: 25 }}
										variant="contained"
										onClick={(e) => {
											e.preventDefault();
											handleReset(
												RESOURCE_SCHEDULER_DEFAULT_VALUE
											);
										}}
									>
										RESET
									</Button>
								)}
								<Button
									style={{ marginLeft: 25 }}
									variant="contained"
									color="primary"
									onClick={(e) => {
										e.preventDefault();
										setOpen(true);
									}}
								>
									UPDATE
								</Button>
							</div>
						</>
					)}
				</div>
				<AuditLogTable
					endpoint={RI_AUDIT_LOG_API}
					params={{ parameter: currentSelection }}
				/>
			</Box>
			{open && (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					data-testid={"dialog-box"}
				>
					<DialogTitle
						id="alert-dialog-title"
						style={{ fontSize: "17px" }}
					>
						{
							"Are you sure you want to modify the value of the selected parameter?"
						}
					</DialogTitle>
					<DialogActions>
						<Button
							onClick={() => {
								handleUpdate();
								setOpen(false);
							}}
							autoFocus
							data-testid={"confirm-save"}
						>
							Yes
						</Button>
						<Button onClick={() => setOpen(false)}>No</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
