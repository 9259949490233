import "../../../scss/CNS.scss";

import {toast, ToastContainer} from "react-toastify";
import {SUBSCRIPTIONS_QUERY} from "./queries.js";
import {DELETE_MUTATION, RUN_MUTATION} from "./mutations.js";
import React, {useEffect, useState} from "react";
import {EmailTextBox} from "../../emailTextBox/EmailTextBox.jsx";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {DataGrid} from "@mui/x-data-grid";
import {Box, Button, CircularProgress} from "@mui/material";
import {getQueryFiles} from "../../../helpers/getQueryFiles.js";
import {CustomP} from "../powertabs/customP/customP.jsx";
import {CustomNoRowsOverlay} from "../common/components/CustomNoRowsOverlay.js";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import Page from "../../Page.jsx";

export const QUEUE_TASKS_QUERY = `mutation($ids: [String!]!){queueCnsSubscriptions(ids: $ids, createExcel: true)}`;
export const UPDATE_EMAILS_QUERY =
    "mutation($emails: [String!]!){ updateCnsGlobalEmailList(recipients: $emails)}";

const renderMultipleValues = (params) => {
    const {formattedValue} = params;
    return (
        <div style={{width: "100%"}}>
            {formattedValue.map((value, i) => {
                if (i === formattedValue.length - 1) return <span>{value}</span>;
                return (
                    <>
                        <span>{value},</span>
                        <br/>
                    </>
                );
            })}
        </div>
    );
};

const columns = [
    {field: "incomplete", headerName: "Incomplete", flex: 0.8},
    {field: "filePrefixName", headerName: "PREFIXFILENAME", flex: 1},
    {
        field: "brands",
        headerName: "BRANDS",
        flex: 1,
        renderCell: renderMultipleValues,
    },
    {field: "departments", headerName: "DEPARTMENTS", flex: 1},
    {field: "categories", headerName: "CATEGORIES", flex: 1},
    {field: "subcategories", headerName: "SUBCATEGORIES", flex: 1},
    {
        field: "toEmailRecipients",
        headerName: "TOEMAILRECIPIENTS",
        flex: 1.2,
        renderCell: renderMultipleValues,
    },
    {
        field: "ccEmailRecipients",
        headerName: "CCEMAILRECIPIENTS",
        flex: 1.2,
        renderCell: renderMultipleValues,
    },
    {field: "dataGroup", headerName: "DATAGROUP", flex: 1},
    {field: "dataGroupId", headerName: "DATAGROUPID", flex: 1},
    {field: "productUniverses", headerName: "PRODUCTUNIVERSES", flex: 0.8},
    {
        field: "aggregationPeriods",
        headerName: "AGGREGATIONPERIODS",
        flex: 0.8,
        renderCell: renderMultipleValues,
    },
];

export const SubscriptionList = () => {
    const [sanitizedCnsData, setSanitizedCnsData] = useState([]);
    const [selectedCnsData, setSelectedCnsData] = useState([]);
    const [globalEmailList, setGlobalEmailList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openRunDialog, setOpenRunDialog] = useState(false)

    const deleteCnsMutation = (subscriptionIds) => {
        makeApolloClientCall(DELETE_MUTATION, false, {ids: subscriptionIds})
            .then((response) => {
                const remainingSubscriptionIds = response.data?.map(subscription => subscription.id)
                const updatedSubscriptions = sanitizedCnsData.filter(subscription =>
                    remainingSubscriptionIds.includes(subscription.id))

                setSanitizedCnsData(updatedSubscriptions)
                toast.success("Delete Success.");
            })
            .catch((error) => toast.error(`${error}`));
    };

    useEffect(() => {
        setIsLoading(true);
        makeApolloClientCall(SUBSCRIPTIONS_QUERY)
            .then((graphqlData) => {
                const cleanCnsData = sanitizeCnsData(graphqlData);
                setSanitizedCnsData(cleanCnsData.data);
                setGlobalEmailList(cleanCnsData.csGlobalEmailList);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setGlobalEmailList, setSelectedCnsData]);

    return (
        <Page name={"Subscriptions"}>
            <div>
                <ToastContainer/>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >

                    <form className={"admin-options"}>
                        {!isLoading && (
                            <div>
                                <EmailTextBox
                                    label={"Global Emails"}
                                    value={globalEmailList}
                                    setError={(error) =>
                                        console.error("=============> error: ", error)
                                    }
                                    onChange={async (value) => {
                                        setGlobalEmailList(value);
                                        await makeApolloClientCall(UPDATE_EMAILS_QUERY, false, {
                                            emails: value,
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {!isLoading ? (
                            <DataGrid
                                slots={{
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }}
                                columns={columns}
                                rows={sanitizedCnsData}
                                checkboxSelection
                                getRowHeight={() => "auto"}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    console.log(selectedCnsData)
                                    setSelectedCnsData(newRowSelectionModel);
                                }}
                                loading={isLoading}
                                rowSelectionModel={selectedCnsData}
                                sx={{maxHeight: "550px"}}
                            />
                        ) : (
                            <CircularProgress sx={{position: "relative", left: "50%"}}/>
                        )}
                    </form>

                    <Box sx={{display: "flex", flexDirection: "row", gap: "10px", marginTop: "1em"}}>
                        <Button
                            disabled={selectedCnsData.length < 1}
                            style={{display: "flex"}}
                            variant="contained"
                            onClick={async (e) => {
                                e.preventDefault();
                                setOpenRunDialog(true)
                            }}
                            color="secondary"
                        >
                            Run
                        </Button>

                        <Button
                            disabled={selectedCnsData.length < 1}
                            style={{display: "flex"}}
                            variant="contained"
                            onClick={async (e) => {
                                e.preventDefault();
                                await Promise.all(selectedCnsData.map((id) => runCnsPreview(id)));
                            }}
                        >
                            Preview
                        </Button>

                        <Button
                            disabled={selectedCnsData.length < 1}
                            style={{display: "flex"}}
                            variant="contained"
                            onClick={async (e) => {
                                e.preventDefault();
                                if (selectedCnsData) {
                                    await Promise.all(
                                        selectedCnsData.map((id) => getQueryFiles(id))
                                    );
                                }
                            }}
                        >
                            Get Queries
                        </Button>

                        {
                            selectedCnsData.length > 0 ?
                                <Button
                                    href={`/cs/edit-subscription/${selectedCnsData[0]}`}
                                    disabled={selectedCnsData.length !== 1}
                                    style={{display: "flex"}}
                                    variant="contained"
                                    className={"white-text"}
                                    target="_blank"
                                >
                                    Edit
                                </Button> :
                                <Button
                                    href={`/cs/create-subscription`}
                                    style={{display: "flex"}}
                                    variant="contained"
                                    color="success"
                                    className={"white-text"}
                                    target="_blank"
                                >
                                    Create
                                </Button>
                        }

                        <Button
                            disabled={selectedCnsData.length < 1}
                            style={{display: "flex", marginLeft: "auto"}}
                            variant="contained"
                            onClick={async (e) => {
                                e.preventDefault();
                                setOpenDeleteDialog(true)
                            }}
                            color="error"
                        >
                            Delete
                        </Button>
                    </Box>

                </Box>

                <div>
                    {deleteDialog(openDeleteDialog, setOpenDeleteDialog, selectedCnsData, deleteCnsMutation)}
                    {runDialog(openRunDialog, setOpenRunDialog, selectedCnsData)}
                </div>
            </div>
        </Page>
    );
};

const runAllCnsMutation = (ids) => {
    makeApolloClientCall(QUEUE_TASKS_QUERY, false, {ids})
        .then(() => toast.success("done"))
        .catch((error) => toast.error(`${error}`));
};

const runCnsPreview = (id) => {
    makeApolloClientCall(RUN_MUTATION, false, {id: id, createExcel: false})
        .then((response) => {
            const rowCounts = response.data.dataAccessMetadata.map(
                ({fileName, rowCount}) => (
                    <div key={fileName}>
                        {fileName} {rowCount} rows
                    </div>
                )
            );

            const content = (
                <div>
                    {rowCounts}
                    <div>CSV Location: {response.data.dataLocation}</div>
                </div>
            );
            toast.success(content, {bodyClassName: "success"});
        })

        .catch((error) => toast.error(`${error}`));
};

const generateAccessor = (key) => ({
    accessor: (row) => row[key].map(({displayName}) => displayName),
    Cell: ({row}) => {
        if (row.original[key].length > 0) {
            return row.original[key].map((c) => <CustomP data={c}/>);
        }
        return <p>ALL</p>;
    },
});

const NESTED_KEYS = {
    brands: generateAccessor("brands"),
    departments: generateAccessor("departments"),
    categories: generateAccessor("categories"),
    subcategories: generateAccessor("subcategories"),
};

const hasIncompleteData = (data) => {
    const result = Object.keys(NESTED_KEYS)
        .map((key) => {
            return data[key].map((item) => {
                return item.displayName === null;
            });
        })
        .flat();
    return result.some((_) => _);
};

const sanitizeCnsData = (graphqlData) => {
    graphqlData.data.forEach((row) => {
        row.incomplete = hasIncompleteData(row) ? "Yes" : "No";
        row.brands = row.brands.map((brand) => brand.displayName);
        row.departments =
            row.departments.length > 0
                ? row.departments.map((department) => department.displayName)
                : "ALL";
        row.categories =
            row.categories.length > 0
                ? row.categories.map((category) => category.displayName)
                : "ALL";
        row.subcategories =
            row.subcategories.length > 0
                ? row.subcategories.map((subcategory) => subcategory.displayName)
                : "ALL";
    });

    return graphqlData;
}

const deleteDialog = (openDeleteDialog, setOpenDeleteDialog, selectedCnsData, deleteCnsMutation) => {
    return (
        <ConfirmationDialog
            isOpen={openDeleteDialog}
            message={"Are you sure you want to delete the selected subscription/s?"}
            handleClose={(value) => setOpenDeleteDialog(value)}
            handleConfirm={async (value) => {
                setOpenDeleteDialog(false)
                if (value && selectedCnsData) {
                    deleteCnsMutation(selectedCnsData)
                }
            }}
            title={"Delete Subscription"}
        />
    )
}

const runDialog = (openRunDialog, setOpenRunDialog, selectedCnsData) => {
    return (
        <ConfirmationDialog
            isOpen={openRunDialog}
            message={"Are you sure you want to generate the report for the selected subscription/s?"}
            handleClose={(value) => setOpenRunDialog(value)}
            handleConfirm={async (value) => {
                setOpenRunDialog(false)
                if (value && selectedCnsData) {
                    runAllCnsMutation(selectedCnsData);
                }
            }}
            title={"Generate Subscription"}
        />
    )
}