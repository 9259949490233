import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import BaseModal from "../../../modals/BaseModal.jsx";

export function CalendarChangeLog({headers}) {
    const auth0AccessToken = window.localStorage.getItem('access_token')
    const [logs, setLogs] = useState([])

    useEffect(() => {
        const abortController = new AbortController()
        const fetchCalendarLogs = async () => {
            return await axios.get(`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/calendar/logs?env=${process.env.REACT_APP_ENV}`, {
                headers: {
                    'authorization': `Bearer ${auth0AccessToken}`
                },
                signal: abortController.signal
            })
        }
        fetchCalendarLogs().then(({data}) => {
            data?.sort((a, b) => b.timestamp?.localeCompare(a.timestamp))
            setLogs(data)
        })
        return () => {
            abortController.abort()
        }
    }, [auth0AccessToken]);
    return (
        <Paper sx={{marginTop: "1em"}}>
            <Accordion sx={{backgroundColor: 'lightgrey'}}>
                <AccordionSummary>
                    Show Changelog
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Timestamp</TableCell>
                                <TableCell>Generation</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Changes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                logs?.map((log) => <TableRow key={log.generation}>
                                        <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                                        <TableCell>{log.generation}</TableCell>
                                        <TableCell>{log.email}</TableCell>
                                        <TableCell>
                                            <BaseModal openButtonTitle={'Show Changes'}
                                                       customStyles={{content: {width: 'min-content'}}}
                                                       headerText={<><span style={{color: 'darkgreen'}}>Added</span>/<span
                                                           style={{color: 'maroon'}}>Removed</span> Rows</>}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                headers.map((header, index) => <TableCell
                                                                    key={index}>{header}</TableCell>)
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            log.added.map((addedRow, index) => {
                                                                return <TableRow
                                                                    sx={{backgroundColor: 'darkgreen !important'}}
                                                                    key={index}>
                                                                    {
                                                                        headers.map((column, idx) =>
                                                                            <TableCell
                                                                                sx={{color: 'white'}}
                                                                                key={idx}>{addedRow[column]}</TableCell>)
                                                                    }
                                                                </TableRow>
                                                            })
                                                        }
                                                        {
                                                            log.removed.map((removedRow, index) => {
                                                                return <TableRow sx={{backgroundColor: 'maroon !important'}}
                                                                                 key={index}>
                                                                    {
                                                                        headers.map((column, idx) =>
                                                                            <TableCell
                                                                                sx={{color: 'white'}}
                                                                                key={idx}>{removedRow[column]}</TableCell>)
                                                                    }
                                                                </TableRow>
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>


                                            </BaseModal>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </AccordionDetails>

            </Accordion>
        </Paper>
    )

}
