import "../../../scss/AwsSqs.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
	createAuditLogEntry,
	RI_AUDIT_LOG_API,
} from "../../../helpers/createAuditLogEntry.js";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import {AuditLogTable} from "../../auditLogTable/AuditLogTable.jsx";

const LIST_QUEUES_API = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/queues/ilab-data-processor`;

const PURGE_QUEUES_API = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/queues/purge`;

export const AwsSqs = () => {
	const [queueData, setQueueData] = useState([]);
	const [selectedUrl, setSelectedUrl] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = React.useState(false);
	const [sqsId, setSqsId] = React.useState("");

	const auth0AccessToken = window.localStorage.getItem("access_token");

	const columns = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			renderCell: (params) => {
				return (
					<a
						onClick={() => {
							setSqsId(params.id);
						}}
					>
						{params.id}
					</a>
				);
			},
		},
		{ field: "pendingMessages", headerName: "Pending Messages", flex: 0.4 },
		{ field: "url", headerName: "URL", flex: 1 },
		{ field: "arn", headerName: "ARN", flex: 1 },
	];

	const fetchQueues = useCallback(() => {
		const abortController = new AbortController();
		return axios.get(LIST_QUEUES_API, {
			headers: {
				authorization: `Bearer ${auth0AccessToken}`,
			},
			signal: abortController.signal,
		});
	}, [auth0AccessToken]);

	useEffect(() => {
		setIsLoading(true);

		fetchQueues()
			.then((data) => {
				setQueueData(data.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [fetchQueues]);

	const handlePurge = (urlsToPurge) => {
		setIsLoading(true);
		return axios
			.post(
				PURGE_QUEUES_API,
				{ queues: urlsToPurge },
				{ headers: { authorization: `Bearer ${auth0AccessToken}` } }
			)
			.then(async () => {
				toast.success("Purge successful!");

				await Promise.all(
					queueData
						.filter((queue) => urlsToPurge.includes(queue.name))
						.map((queue) => {
							return createAuditLogEntry(RI_AUDIT_LOG_API, {
								queue: queue.name,
								count: queue.pendingMessages,
							});
						})
				);

				// await createAuditLogEntry(RI_AUDIT_LOG_API, {
				// 	queue: queueData
				// 		.filter((queue) => urlsToPurge.includes(queue.name))
				// 		.map((queue) => ({
				// 			name: queue.name,
				// 			messageCount: queue.pendingMessages,
				// 		})),
				// });
				await fetchQueues();
			})
			.catch((error) => {
				toast.error(`${error} - try again after 1 minute`);
				return false;
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<ToastContainer />
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					width: "90%",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<Typography
					variant={"h4"}
					sx={{ marginTop: "8px", marginBottom: "16px" }}
				>
					AWS SQS
				</Typography>
				<Paper style={{ height: "100%", minHeight: "2em" }}>
					{!isLoading ? (
						<DataGrid
							initialState={{
								sorting: {
									sortModel: [
										{
											field: "pendingMessages",
											sort: "desc",
										},
									],
								},
							}}
							disableRowSelectionOnClick
							getRowId={(row) => row.name}
							columns={columns}
							rows={queueData}
							checkboxSelection
							getRowHeight={() => "auto"}
							onRowSelectionModelChange={(
								newRowSelectionModel
							) => {
								setSelectedUrl(newRowSelectionModel);
							}}
							rowSelectionModel={selectedUrl}
						/>
					) : (
						<CircularProgress
							sx={{ position: "relative", left: "50%" }}
						/>
					)}
				</Paper>
				<Box sx={{ marginLeft: "auto", marginTop: "1em" }}>
					<Button
						disabled={selectedUrl.length < 1}
						style={{ marginLeft: 25 }}
						variant="contained"
						color="error"
						onClick={async (e) => {
							e.preventDefault();
							setOpen(true);
						}}
						data-testid={"purge-button"}
					>
						Purge
					</Button>
				</Box>
			</Box>
			{open && (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					data-testid={"dialog-box"}
				>
					<DialogTitle
						id="alert-dialog-title"
						style={{ fontSize: "17px" }}
					>
						{
							"Are you sure you want to purge messages from the selected queues?"
						}
					</DialogTitle>
					<DialogActions>
						<Button
							onClick={() => {
								void handlePurge(selectedUrl);
								setOpen(false);
							}}
							autoFocus
							data-testid={"confirm-purge"}
						>
							Yes
						</Button>
						<Button onClick={() => setOpen(false)}>No</Button>
					</DialogActions>
				</Dialog>
			)}

			{sqsId && (
				<Dialog
					open={true}
					onClose={() => setSqsId("")}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth={true}
					maxWidth={"md"}
					test-id={"contract-info-dialog"}
				>
					<DialogTitle
						id="alert-dialog-title"
						className={"account-dialog-title"}
					>
						{sqsId}
						<IconButton
							onClick={() => setSqsId("")}
							className={"button-close"}
							test-id={"contract-modal-close-btn"}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<AuditLogTable endpoint={RI_AUDIT_LOG_API} params={{queue: sqsId }} />
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};
