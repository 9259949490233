export function IncompleteFilter({setFilter, filterValue}) {
    return <select
        onChange={event => event.target.value === "All" ? setFilter(undefined) : setFilter(event.target.value)}
        style={{width: "100%"}}
        value={filterValue ? filterValue : "all"}
    >
        <option value="All">Show All</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
    </select>;
}
