import {GraphqlQueryTable} from "./GraphqlQueryTable";

export const APPROVALS_QUERY = `query Approvals {
        data:approvals {
            approvalId
            marketGroup
            approvalStatus
            companyName
            clientSubscriptionId
            approvalCategory
            marketGroupId
        }
    }`
export const APPROVALS_COLUMNS = () => [
    {
        Header: 'Company Name',
        accessor: 'companyName'
    },
    {
        Header: 'Client Subscription ID',
        accessor: 'clientSubscriptionId'
    },
    {
        Header: 'Approval ID',
        accessor: 'approvalId'
    },
    {
        Header: 'Approval Category',
        accessor: 'approvalCategory'
    },
    {
        Header: 'Market Group ID',
        accessor: 'marketGroupId'
    },
    {
        Header: 'Market Group',
        accessor: 'marketGroup'
    },
    {
        Header: 'Status',
        accessor: 'approvalStatus'
    }
];

export const Approvals = () => {
    return (<GraphqlQueryTable
        pageName="Approvals"
        generateColumns={APPROVALS_COLUMNS}
        query={APPROVALS_QUERY}
    />)
}
