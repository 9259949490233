import gql from 'graphql-tag'
import {ApolloClient, InMemoryCache} from "@apollo/client";

export const createApolloClient = () => new ApolloClient({
    cache: new InMemoryCache(),
    uri: `${process.env.REACT_APP_NIRVANA_CORE_API_URL}`,
    headers: {"x-api-key": process.env.REACT_APP_NIRVANA_CORE_API_KEY,}
})
export const apolloClient = createApolloClient()

export const makeApolloClientCall = async (graphqlQuery, useCache, variables) => {
    const cachePolicy = useCache ? 'cache-first' : 'no-cache';
    return apolloClient
    .query({
        query: gql` ${graphqlQuery} `,
        fetchPolicy: cachePolicy,
        variables: variables
    }).then((response) => {
        if (response.error) {
            console.error(`=============> Apollo query ${graphqlQuery} failed with error `, response.error);
            throw new Error(`Apollo query ${graphqlQuery} failed!`)
        }
        return response.data
    }).catch((error) => {
        console.error("=============> error: ", error);
        throw(error)
    });
};