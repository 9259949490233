import React, {useState} from "react";
import validator from 'validator';
import {Autocomplete, Chip, TextField} from "@mui/material";

export const EmailTextBox = ({label, onChange, error, setError, value, clearSelectedValues = false, testId}) => {
    const [selected, setSelected] = useState(value)
    return (
        <div>
            <Autocomplete
                multiple
                id={label}
                freeSolo
                options={[]}
                data-testid={testId || label}
                value={clearSelectedValues ? [] : selected}
                onChange={(_event, onChangeValue) => {
                    const filtered = onChangeValue.filter(v => {
                        const result = validator.isEmail(v)
                        setError(!result)
                        return result
                    })
                    setSelected(
                        filtered
                    )
                    onChange(filtered)
                }}
                renderTags={(renderTagsValue, getTagProps) =>
                    renderTagsValue.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({index})} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={label}
                        error={!!error}
                        helperText={error}
                    />
                )}
            />
        </div>
    );
}
