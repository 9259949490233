import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getOptionalAuthorizationRedirect} from './accessTokenValidator';
import {Redirect, Route} from "react-router-dom";
import {Header} from "../header/Header.jsx";

export function RouteWithAuthorization(props) {
    const optionalRedirect = getOptionalAuthorizationRedirect(
        window.localStorage.getItem('access_token'),
        window.location,
        props.path
    )
    return (
        <Fragment>
            <Header/>
            <Route {...props} component={props.component}>
                {optionalRedirect ? <Redirect to={`/${optionalRedirect}`}/> : ''}
            </Route>
        </Fragment>
    )
}

RouteWithAuthorization.propTypes = {
    component: PropTypes.func.isRequired
}