import {useFilters, usePagination, useSortBy, useTable} from "react-table";
import React, {useMemo} from "react";

export const Table = ({columns, data}) => {

    const memoColumns = useMemo(() => columns, [columns])

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 30,
            maxWidth: 400
        }),
        []
    )

    function DefaultColumnFilter({column: {filterValue, setFilter},}) {
        return (
            <input
                aria-label={'input-filter'}
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value)
                }}
            />
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable({
            columns: memoColumns, data, defaultColumn,
            initialState: {pageIndex: 0, pageSize: 50},
        },
        useFilters,
        useSortBy,
        usePagination
    )

    return (
        <>
            <div className={'table'}>
                <table {...getTableProps()} className={'ReactTable'}>
                    <thead>
                    {headerGroups.map((headerGroup, i )=> (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`${i}-tr`}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`${i}-header`}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''
                                    }
                                </span>
                                    <div
                                        className={"rt-filterable-content"}>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
                <span>Page <strong>{pageIndex + 1} of {pageOptions.length}</strong></span>
                <select
                    aria-label="page-size-select"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option aria-label="page-size-option" key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
            </div>
        </>
    )
}