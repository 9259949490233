import React from 'react';
import PropTypes from 'prop-types'


const HeaderTile = ({menuItems}) => {
    return (
        <div className={'nav-bar-selections'}>
            {menuItems
                .map((item, i) =>
                    <div key={i} className={'divider'}>
                        <a href={item.url} id={`${item.name}-dropdown`}
                           className={`dropdown-menu-link-large ${window.location.pathname === item.url ? 'active' : ''}`}>
                            <div className={'dropdown-menu-link-container'}>
                                {item.name}
                            </div>
                        </a>
                    </div>
                )}
        </div>
    )
}

HeaderTile.defaultProps = {
    nestedContent: undefined
}

HeaderTile.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.oneOfType(
            [
                PropTypes.string,
                PropTypes.shape({
                    name: PropTypes.string,
                    url: PropTypes.string
                })
            ])
    )
};

export default HeaderTile;