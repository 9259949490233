import React from "react";

import "graphiql/graphiql.css";
import "./Graphiql.css";
import GraphiQL from "graphiql";
import JwtDecode from "jwt-decode";

export const graphQLFetcher = (graphQLParams) => {
    return fetch(process.env.REACT_APP_SDN_API_BASE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${window.localStorage.getItem('access_token')}`
        },
        body: JSON.stringify(graphQLParams)
    }).then(response => response.json());
}

export const Explorer = () => {
    const access_token = JwtDecode(window.localStorage.getItem('access_token'))
    const payload = access_token["https://aot/userSecurityFields"]["auth"]
    const query = `# Welcome to SDN Office Explorer!
    
# You can find documentation about authentication payloads here:
# https://spins.atlassian.net/wiki/spaces/NRVO/pages/82965332033/Auth+Payload

# Current user payload: ${JSON.stringify(payload)}

query {
  companies {
    name
  }
}
`

    return (
        <GraphiQL fetcher={graphQLFetcher} docExplorerOpen={false} tabs={true} query={query}/>
    )
}