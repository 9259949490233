import '../../../scss/AccessAdmin.scss';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, IconButton, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import {makeApolloClientCall as nirvanaApolloClientCall} from "../../../helpers/queryNirvanaCoreApi.js";
import {CREATE_COMPANY_MUTATION, CREATE_NOSHE_SUBSCRIPTION_MUTATION} from "./mutation.js";
import {NOSHE_SUBSCRIPTION_ID} from "./AccessAdmin/AccessAdmin.jsx";
import {CURRENT_PERIOD_QUERY} from "./queries.js";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {toast, ToastContainer} from "react-toastify";
import {ItemStatusIndicator} from "../common/components/ItemStatusIndicator.jsx";


const SATORI_SECURITY_ENDPOINT = `${process.env.REACT_APP_SATORI_BASE_DOMAIN}/security/organizations`


export const AccountModal = ({isOpen, handleClose, data, onDataUpdate, handleLogAction, loadCompanies}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        satoriOrganizationId: data?.satoriOrganizationId,
        destiniClientId: data?.destiniClientId,
        name: data?.name,
        companyUniqueId: data?.name.replaceAll(" ", "").toLowerCase()
    })
    const [isCreatingCompany, setIsCreatingCompany] = useState(null)
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(null)
    const [isEnablingSatoriScorecard, setIsEnablingSatoriScorecard] = useState(null)

    useEffect(() => {
        setFormData({
            satoriOrganizationId: data?.satoriOrganizationId,
            destiniClientId: data?.destiniClientId,
            name: data?.name,
            companyUniqueId: data?.name.replaceAll(" ", "").toLowerCase()
        })
        setIsCreatingCompany(null)
        setIsUpdatingSubscription(null)
        setIsEnablingSatoriScorecard(null)
    }, [data])

    const handleConfirm = (value) => {
        setIsConfirmationOpen(false)
        if (value) {
            handleFormSubmit().then()
        }
    }

    const handleFormSubmit = async () => {
        setIsFormSubmitting(true)
        const currentPeriod = (await makeApolloClientCall(CURRENT_PERIOD_QUERY, false, {})).data.abbreviation
        const contractNumber = currentPeriod.replace("-", "")
        const nosheInput = {
            input: {
                companyUuid: data?.uuid,
                destiniClientId: formData?.destiniClientId?.length > 0 ? formData?.destiniClientId : null,
                satoriOrganizationId: formData?.satoriOrganizationId ? parseInt(formData?.satoriOrganizationId) : null,
                contractNumber: contractNumber,
                subscriptionUuid: NOSHE_SUBSCRIPTION_ID
            }
        }
        setIsUpdatingSubscription(true)
        if (data?.needAccountSetup) {
            setIsCreatingCompany(true)
            const result
                = await nirvanaApolloClientCall(CREATE_COMPANY_MUTATION, false, {
                input: {
                    name: formData?.name,
                    companyUniqueId: formData?.companyUniqueId
                }
            })
            loadCompanies();
            toast.success(
                `New Company ${result?.createCompany?.uuid} successfully saved.`
            );
            setIsCreatingCompany(false)
            nosheInput.input.companyUuid = result?.createCompany?.uuid
        }
        nirvanaApolloClientCall(CREATE_NOSHE_SUBSCRIPTION_MUTATION, false, nosheInput)
            .then(response => {
                if (response?.createNosheSubscription?.uuid) {
                    onDataUpdate(nosheInput.input)
                }
                setIsUpdatingSubscription(false)
                handleLogAction(nosheInput.input)
            })
            .then(response => {
                if (formData?.satoriOrganizationId) {
                    setIsEnablingSatoriScorecard(true)
                    const satoriWindow =
                        window.open(
                            `${SATORI_SECURITY_ENDPOINT}/${formData.satoriOrganizationId}/enable_show_scorecards`,
                            "satori-show-scorecards"
                        );
                    const interval = setInterval(() => {
                        if (satoriWindow.closed) {
                            setIsEnablingSatoriScorecard(false)
                            clearInterval(interval);
                            toast.success("Subscription successfully saved.")
                            setIsConfirmationOpen(false)
                            setIsFormSubmitting(false)
                            handleClose()
                        }
                    }, 500);
                } else {
                    toast.success("Subscription successfully saved.")
                    setIsConfirmationOpen(false)
                    setIsFormSubmitting(false)
                    handleClose()
                }
            })
            .catch(error => {
                toast.error(`An error occurred while saving the subscription. ${error}`)
                setIsConfirmationOpen(false)
                setIsFormSubmitting(false)
                handleClose()
            })

    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
                test-id={"account-modal-dialog"}
            >
                <DialogTitle id="alert-dialog-title" className={"account-dialog-title"}>
                    <div>
                        {data?.name}
                        <p className={"uuid"}> {data?.uuid} </p>
                    </div>
                    <IconButton onClick={handleClose} className={"button-close"}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{"paddingBottom": 0}}>
                    <div>
                        <form>
                            <div className={"account-modal-form"}>
                                {
                                    data?.needAccountSetup && <>
                                        <div style={{display: "flex"}}>
                                            <TextField
                                                label={"Company Name"}
                                                variant="outlined"
                                                fullWidth={true}
                                                value={formData.name}
                                                disabled={true}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            <TextField
                                                label={"Company Unique ID"}
                                                variant="outlined"
                                                fullWidth={true}
                                                value={formData.companyUniqueId}
                                                onChange={(event) => setFormData({
                                                    ...formData,
                                                    companyUniqueId: event.target.value
                                                })}
                                            />
                                        </div>
                                    </>
                                }
                                <TextField
                                    label="Satori Org ID"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={formData?.satoriOrganizationId ?? data?.satoriOrganizationId}
                                    onChange={(event) => setFormData({
                                        ...formData,
                                        satoriOrganizationId: event.target.value
                                    })}
                                />
                                <TextField
                                    label="Destini Client ID"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={formData?.destiniClientId ?? data?.destiniClientId}
                                    onChange={(event) => setFormData({
                                        ...formData,
                                        destiniClientId: event.target.value
                                    })}
                                />
                                <ItemStatusIndicator
                                    text={"Creating new company..."}
                                    isCompleted={isCreatingCompany === null ? null : !isCreatingCompany}
                                />
                                <ItemStatusIndicator
                                    text={"Updating subscription..."}
                                    isCompleted={isUpdatingSubscription === null ? null : !isUpdatingSubscription}
                                />
                                <ItemStatusIndicator
                                    text={"Enabling scorecards in SATORI..."}
                                    isCompleted={isEnablingSatoriScorecard === null ? null : !isEnablingSatoriScorecard}
                                />
                            </div>
                        </form>
                    </div>
                </DialogContent>
                <DialogActions className={"account-dialog-action"}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setFormData({
                                satoriOrganizationId: data.satoriOrganizationId,
                                destiniClientId: data.destiniClientId,
                                name: data?.name,
                                companyUniqueId: data?.name.replaceAll(" ", "").toLowerCase()
                            })
                            handleClose()
                        }}> Cancel </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsConfirmationOpen(true)
                        }}> {data?.needAccountSetup || (!data?.satoriOrganizationId && !data?.destiniClientId) ? "Create" : "Update"} Subscription </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                isOpen={isConfirmationOpen}
                message={"Are you sure you want to save your changes?"}
                handleClose={() => setIsConfirmationOpen(false)}
                handleConfirm={(value) => handleConfirm(value)}
                title={"Save Account Changes"}
                test-id={"confirmation-dialog"}
            />
            <ToastContainer/>
            <Backdrop sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 999}} open={isFormSubmitting}>
                <CircularProgress className={"spinning-loader"}/>
            </Backdrop>
        </div>
    )

};