import {toast, ToastContainer} from "react-toastify";
import {Form, Formik} from "formik";
import {Button} from "@mui/material";
import React, {useState} from "react";
import {query} from "../../../helpers/queryNirvanaApi.js";
import readXlsxFile from 'read-excel-file'
import {Table} from "../../tables/Table.jsx";
import {loadNewMarketKeys} from "./LoadNewMarketKeys.js";

const COLUMNS = [
    {
        Header: 'Market Key',
        columns: [
            {
                Header: 'Current',
                accessor: 'current_market_key',
            },
            {
                Header: 'New',
                accessor: 'new_market_key'
            },
        ]
    },
    {
        Header: 'Market Name',
        columns: [
            {
                Header: 'Current',
                accessor: 'current_market_name'
            },
            {
                Header: 'New',
                accessor: 'new_market_name'
            },
        ]
    }
]

const schema = {
    'Status': {
        prop: 'status',
        type: String,
        required: true
    },
    'current market_key': {
        prop: 'current_market_key',
        type: String,
        required: true
    },
    'new market_key': {
        prop: 'new_market_key',
        type: String,
        required: true
    },
    'current SPINS description': {
        prop: 'current_market_name',
        type: String,
        required: true
    },
    'new SPINS description': {
        prop: 'new_market_name',
        type: String,
        required: true
    }
}

export const UpdateMarkets = () => {
    const [data, setData] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [nirvanaLoadedData, setNirvanaLoadedData] = useState([{current_market_key: 'Load market keys to see preview'}])

    async function handleUpdateMarketKeys() {
        const result = await query(`
                mutation {
                    updateMarketKeys
                }
            `)

        if (result.errors) {
            toast.error('Error updating market keys')
        } else {
            toast.success('Market keys updated')
        }
    }

    async function handleFileUpload(event) {
        let file = event.target.files[0];

        const extension = file.name.split('.').pop().toLowerCase()
        const isExcelFile = ['xlsx'].indexOf(extension) > -1;

        if (isExcelFile) {
            await readXlsxFile(file, {
                schema, transformData(data) {
                    return data.map((row) => {
                        return row.map((obj) => {
                            return obj || ""
                        })
                    })
                }
            }).then(({rows, errors}) => {
                if (errors.length) {
                    event.target.value = ''
                    toast.error(`Required columns: 'Status', 'current market_key', 'new market_key', 'current SPINS description', 'new SPINS description'`)
                } else {
                    setData(rows.filter((row) => {
                        return (
                            (['NEW MARKET KEY', 'NEW MARKET KEY AND NEW NAME', 'NEW NAME', 'NEW NAME AND NEW CHANNEL/OUTLET'].includes(row['status'])) &&
                            (
                                row['current_market_key'] !== '' ||
                                row['new_market_key'] !== '' ||
                                row['current_market_name'] !== '' ||
                                row['new_market_name'] !== ''
                            )
                        )
                    }))
                }
            })
        } else {
            event.target.value = ''
            toast.error("Please upload an excel file")
        }
    }

    return (
        <div className='page-wrapper'>
            <ToastContainer/>
            <div className='form'>
                <h1>Update Nirvana/Venus Market Keys</h1>
                <Formik
                    initialValues={{
                        file: '',
                    }}
                    onSubmit={async () => {
                        await handleUpdateMarketKeys()
                    }}
                >
                    {({
                          isSubmitting, setSubmitting
                      }) => (
                        <Form>
                            <div style={{"marginTop": "10px", "marginBottom": "10px"}}>
                                <input id="file" name="file" type="file" aria-label="upload-file"
                                       onChange={async (event) => {
                                           await handleFileUpload(event)
                                       }}/>
                            </div>


                            <div>
                                <Button
                                    variant="outlined"
                                    style={{"marginRight": "5px"}}
                                    disabled={isSubmitting || !data}
                                    aria-label={'load-market-keys'}
                                    onClick={async () => {
                                        setSubmitting(true)

                                        try {
                                            await loadNewMarketKeys(data).then((result) => {
                                                setNirvanaLoadedData(result.data.newMarketKeys)
                                                setDataLoaded(true)
                                            })
                                        } catch (e) {
                                            toast.error(e.message)
                                        }

                                        setSubmitting(false)
                                    }}
                                >
                                    Load Market Keys
                                </Button>

                                <Button
                                    variant="contained"
                                    color="success"
                                    style={{"marginLeft": "5px"}}
                                    type="submit"
                                    disabled={isSubmitting || !data || !dataLoaded}
                                    aria-label={'update-market-keys'}
                                >
                                    Update Market Keys
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className='table-container' style={{"width": "50%"}}>
                <Table columns={COLUMNS} data={nirvanaLoadedData}/>
            </div>
        </div>
    )
}