import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {useCallback, useEffect, useState} from "react";
import {
    CATEGORIES_QUERY,
    COMPANIES_QUERY,
    GEO_FAMILY_QUERY,
    MDM_BRANDS_QUERY,
    SUBCATEGORIES_QUERY,
    DEPARTMENTS_QUERY
} from "./queries.js";
import axios from "axios";

const getValueFromEdges = (edges) => {
    return edges?.map((edge) => edge.node)
}


export const useFetchMdmCompanies = () => {
    const [isCompaniesLoading, setIsCompaniesLoading] = useState(true);
    const [companyList, setCompanyList] = useState([])


    const fetchMdmCompanies = async () => {
        let hasNextPage = true
        let endCursor = ""
        let companyList = []
        while (hasNextPage) {
            const response = await makeApolloClientCall(COMPANIES_QUERY, false, {
                after: endCursor
            })
            const companies = response.mdmCompanies.edges.map((edge) => ({
                displayName: edge.node.displayName,
                value: edge.node.externalReferences.find(ref => ref.system === "salesforce" && ref.type === 'id')?.identifier
            })).filter(company => company.value)

            endCursor = response.mdmCompanies.pageInfo.endCursor
            hasNextPage = response.mdmCompanies.pageInfo.hasNextPage
            companyList = [...companyList, ...companies]
        }
        setCompanyList(companyList.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
        ))
    }


    useEffect(() => {
        fetchMdmCompanies().finally(() => setIsCompaniesLoading(false))
    }, [])


    return {isCompaniesLoading, companyList}
}

export const useFetchMdmBrands = () => {
    const [brandList, setBrandList] = useState([])
    const [isBrandListLoading, setIsBrandListLoading] = useState(true);

    const fetchMdmBrands = async () => {
        setIsBrandListLoading(true)
        await makeApolloClientCall(MDM_BRANDS_QUERY, false,).then((response) => {
            setBrandList(getValueFromEdges(response.mdmBrands.edges))
        })
    }
    useEffect(() => {
        fetchMdmBrands().finally(() => setIsBrandListLoading(false))
    }, [])

    return {brandList, isBrandListLoading}
}

export const useFetchGeoFamilyIds = () => {
    const [geoFamilyIds, setGeoFamilyIds] = useState([])
    const [isGeoFamilyIdsLoading, setIsGeoFamilyIdsLoading] = useState(true)

    const fetchGeoFamilyIds = async () => {
        setIsGeoFamilyIdsLoading(true)
        await makeApolloClientCall(GEO_FAMILY_QUERY, false).then((response) => {
            const uniqueIds = response.geoFamilyIds.filter(function (item, pos, self) {
                return self.findIndex(i => i.geo_family_id === item.geo_family_id) === pos;
            })
            setGeoFamilyIds(uniqueIds.map((g) => {
                return {
                    value: g.geo_family_id,
                    displayName: `${g.geo_family_id} - ${g.geo_family_description}`,
                    majorChannelCode: g.major_channel_code
                }
            }))
        })
    }

    useEffect(() => {
        fetchGeoFamilyIds().finally(() => setIsGeoFamilyIdsLoading(false))
    }, []);

    return {geoFamilyIds, isGeoFamilyIdsLoading}
}

export const useFetchSubcategories = () => {
    const [subcategories, setSubcategories] = useState([])
    const [isSubcategoriesLoading, setIsSubcategoriesLoading] = useState(true);


    const fetchSubcategories = async () => {
        setIsSubcategoriesLoading(true)
        await makeApolloClientCall(SUBCATEGORIES_QUERY, false,).then((response) => {
            setSubcategories(getValueFromEdges(response.sdnDeliveryPeriodSnapshotSubcategories.edges))
        })
    }
    useEffect(() => {
        fetchSubcategories().finally(() => setIsSubcategoriesLoading(false))
    }, [])

    return {subcategories, isSubcategoriesLoading}
}
export const useFetchCategories = () => {
    const [categories, setCategories] = useState([])
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);


    const fetchCategories = async () => {
        setIsCategoriesLoading(true)
        await makeApolloClientCall(CATEGORIES_QUERY, false,).then((response) => {
            setCategories(getValueFromEdges(response.sdnDeliveryPeriodSnapshotCategories.edges).map((category) => ({
                ...category,
                subcategories: getValueFromEdges(category.subcategories.edges)
            })))
        })
    }
    useEffect(() => {
        fetchCategories().finally(() => setIsCategoriesLoading(false))
    }, [])

    return {categories, isCategoriesLoading}
}

export const useFetchDepartments = () => {
    const [departments, setDepartments] = useState([])
    const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(true);


    const fetchDepartments = async () => {
        setIsDepartmentsLoading(true)
        await makeApolloClientCall(DEPARTMENTS_QUERY, false,).then((response) => {
            setDepartments(
                getValueFromEdges(response.sdnDeliveryPeriodSnapshotDepartments.edges)
                    .map((department) => (
                        {
                            ...department,
                            categories: getValueFromEdges(department.categories.edges).map((category) => ({
                                ...category,
                                subcategories: getValueFromEdges(category.subcategories.edges)
                            }))
                        }
                    ))
            )
        })
    }
    useEffect(() => {
        fetchDepartments().finally(() => setIsDepartmentsLoading(false))
    }, [])

    return {departments, isDepartmentsLoading}
}

export const useListState = (initialState = []) => {
    const [list, setList] = useState(initialState)
    const addToList = (item) => {
        setList((prevState) => Array.isArray(item) ? [...prevState, ...item] : [...prevState, item])
    }
    const resetList = () => setList(initialState)
    const removeFromList = (...indices) => {
        setList((current) => current.filter((_, index) => !indices.includes(index)));

    }
    return [list, addToList, resetList, removeFromList]
}


export const useFetchSubscriptions = (companySfId, application = 'ignitePlus') => {
    const [activeSubscriptions, setActiveSubscriptions] = useState([])
    const [isActiveSubscriptionsLoading, setIsActiveSubscriptionsLoading] = useState(true);

    const fetchActiveSubscriptions = useCallback(async () => {
        setIsActiveSubscriptionsLoading(true)
        if (companySfId === undefined) {
            setActiveSubscriptions([])
            return
        }
        return await axios.get(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/subscriptions?companyId=${companySfId}&application=${application}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            },
        })
            .then(({data}) => setActiveSubscriptions(data))
            .catch(() => setActiveSubscriptions([]))
            .finally(() => setIsActiveSubscriptionsLoading(false))
    }, [companySfId, application])

    useEffect(() => {
        fetchActiveSubscriptions().finally(() => setIsActiveSubscriptionsLoading(false))
    }, [fetchActiveSubscriptions])

    return {activeSubscriptions, fetchActiveSubscriptions, isActiveSubscriptionsLoading}
}