export const DELETE_MUTATION = `mutation($ids: [String!]!) {
  data:deleteCnsSubscription(ids: $ids) {
    id
  }
}`

export const UPSERT_MUTATION = `mutation($data: CNSSubscriptionInput!){
  upsertCnsSubscription(data: $data) {
    filePrefixName
  }
}`

export const RUN_MUTATION = `mutation($id: String!, $createExcel: Boolean) {
  data:createCnsFromSubscriptionId(id: $id, createExcel: $createExcel) {
    dataLocation
    dataAccessMetadata {
      fileName
      rowCount
    }
  }
}`

export const UPDATE_ADMIN_OPTIONS_MUTATION = `
    mutation($adminOptions: AdminOptionsInput!, $includeSdnoMapping: Boolean) {
        updateCnsAdminOptions (adminOptions: $adminOptions, includeSdnoMapping: $includeSdnoMapping) {
            marketKeysIndex
            designations
            wholesalerRegionMapping {
                marketKey
                defaultValue
                overrideValue
                sdnoValue
            }
    }
}`

export const DELETE_VM_INSTANCE = `
    mutation($vmNames: [String!]!) {
      cnsDeleteVmInstances(vmNames: $vmNames)
    }
`