import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {ADMIN_OPTIONS_QUERY, DESIGNATIONS_QUERY} from "./queries.js";
import * as React from "react";
import {useEffect, useState} from "react";
import {Backdrop, Box, Button, CircularProgress} from "@mui/material";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import {toast, ToastContainer} from "react-toastify";
import {ComboBox} from "../../comboBox/ComboBox.jsx";
import Page from "../../Page.jsx";
import {UPDATE_ADMIN_OPTIONS_MUTATION} from "./mutations.js";
import {DataGrid} from "@mui/x-data-grid";
import {CustomNoRowsOverlay} from "../common/components/CustomNoRowsOverlay.js";
import {AddEditItemModal} from "../../modals/AddEditItemModal.jsx";
import axios from "axios";


export const AdminOptions = () => {
    const [cachedData, setCachedData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [upsertItemInfo, setUpsertItemInfo] = useState({})
    const [formData, setFormData] = useState({})
    const [confirmationModalInfo, setConfirmationModalInfo] = useState({})

    const auth0AccessToken = window.localStorage.getItem("access_token");

    // update form data on state change
    useEffect(() => {
            const marketKeys = cachedData.adminOptions?.marketKeysIndex?.map(x => cachedData.marketKeysLookupTable[x.toString()])
            const designations = cachedData.adminOptions?.designations?.map(x => cachedData.designationsLookupTable[x.toString()])
            const wholesalerRegionMapping = cachedData.adminOptions?.wholesalerRegionMapping?.map(t => {
                t["id"] = t.marketKey
                return t
            })

            if (Object.keys(cachedData).length !== 0) {
                setFormData(() => {
                    return {
                        marketKeys: marketKeys,
                        designations: designations,
                        wholesalerRegionMapping: wholesalerRegionMapping,
                    }
                })
            }
        },
        [cachedData]
    )

    // when form data is updated, remove loading
    useEffect(() => {
        if (Object.keys(formData).length !== 0) {
            setIsLoading(false)
        }
    }, [formData])

    // one-off events
    useEffect(() => {
        Promise.all([
            makeApolloClientCall(ADMIN_OPTIONS_QUERY, false, {includeSdnoMapping: true}),
            makeApolloClientCall(DESIGNATIONS_QUERY, false, {designationIds: null}),
            axios.get(`${process.env.REACT_APP_RIS_API_URL}/caching/sdn/market-keys`, {
                headers: {
                    'Authorization': `Bearer ${auth0AccessToken}`,
                    'Content-Type': 'application/json'
                }
            }),
        ]).then(responses => {
            setCachedData({
                adminOptions: responses[0].cnsAdminOptions,
                marketKeysLookupTable: Object.fromEntries(responses[2].data),
                designationsLookupTable: Object.fromEntries(
                    responses[1].designations
                        .sort((a, b) => a.displayName.toUpperCase().localeCompare(b.displayName.toUpperCase()))
                        .map(x => [x.value.toString(), x])
                )
            })
        }).catch(error => /* istanbul ignore next */ {
            toast.error(`${error.networkError.result.errors[0].message}`);
            window.alert("Network Error. Click OK to reload")
            window.location.reload()
        })
    }, [])

    const onUpdateCombobox = (value, key) => {
        const updateState = () => {
            const adminOptions = {...cachedData.adminOptions}
            adminOptions[key] = value
            onConfirmChanges(adminOptions)
        }

        if (value.length === 0 && cachedData.adminOptions[key].length > 1) {
            setConfirmationModalInfo({
                title: "Confirm Clear",
                message: `Are you sure you want to remove all ${key}?`,
                handler: (isConfirmed) => {
                    if (isConfirmed) {
                        updateState()
                    }
                    setConfirmationModalInfo({})
                }
            })
        } else {
            updateState()
        }
    }

    const onConfirmChanges = (value) => {
        setIsLoading(true)
        makeApolloClientCall(UPDATE_ADMIN_OPTIONS_MUTATION, false, {
            adminOptions: {
                marketKeysIndex: value.marketKeysIndex,
                designations: value.designations,
                wholesalerRegionMapping: Object.values(value.wholesalerRegionMapping)
                    .filter(m => m.overrideValue || m.defaultValue)
                    .map(m => {
                        return {
                            marketKey: m.marketKey,
                            defaultValue: m.defaultValue,
                            overrideValue: null,
                        }
                    })
            },
            includeSdnoMapping: true,
        }).then((response) => {
            toast.success("Admin Options Saved");
            return response
        }).then((response) => {
            setCachedData((prevState) => {
                return {
                    ...prevState,
                    adminOptions: response.updateCnsAdminOptions
                }

            })
        }).catch(error => {
            toast.error(`${error.networkError.result.errors[0].message}`);
            setIsLoading(false)
        })
    }

    const onAddNewItem = (item) => {
        if (!formData.wholesalerRegionMapping.find(p => p.marketKey.toString() === item.marketKey)) {
            return onEditItem(item)
        } else {
            return "Market Key " + item.marketKey + " already exists..."
        }
    }

    const onEditItem = (item) => {
        onConfirmChanges({
            ...cachedData.adminOptions,
            wholesalerRegionMapping: [
                ...cachedData.adminOptions.wholesalerRegionMapping.filter(t => t.marketKey !== parseInt(item.marketKey)),
                {
                    ...Object.fromEntries(Object.entries(item).filter(([_, v]) => v?.length !== 0)),
                    "marketKey": parseInt(item.marketKey)
                }
            ]
        })
        return ""
    }

    const onRemoveItem = (marketKey) => {
        onConfirmChanges({
            ...cachedData.adminOptions,
            wholesalerRegionMapping: [
                ...cachedData.adminOptions.wholesalerRegionMapping
                    .map(t => t.marketKey !== marketKey ? t : {
                        marketKey: marketKey,
                        sdnoValue: t.sdnoValue,
                        defaultValue: null,
                        overrideValue: null,
                    })
                    .filter(t => t.defaultValue !== null || t.overrideValue !== null || t.sdnoValue !== null)
            ]
        })
    }

    const columns = [
        {
            headerName: "Market Key",
            maxWidth: 120,
            field: 'marketKey',
            flex: 1,
            headerClassName: 'mapping-table-header',
            sortable: false,
        },
        {
            headerName: "Default Region",
            field: 'defaultValue',
            flex: 1,
            headerClassName: 'mapping-table-header',
            sortable: false
        },
        {
            headerName: "SDNO Region",
            field: 'sdnoValue',
            flex: 1,
            headerClassName: 'mapping-table-header',
            sortable: false
        }, {
            headerName: "Preview",
            flex: 1,
            headerClassName: 'mapping-table-preview-header',
            sortable: false,
            cellClassName: 'mapping-table-preview-cell',
            valueGetter: (param) => param.row.sdnoValue ?? param.row.defaultValue,
        },
        {
            field: "action",
            width: 150,
            maxWidth: 150,
            align: "right",
            headerAlign: "center",
            headerName: "Action",
            sortable: false,
            renderCell: (param) => {
                return (
                    <>
                        {(param.row.defaultValue)
                            ? <Button size="small" onClick={() => onRemoveItem(param.id)}
                                      color="error">{param.row.sdnoValue ? "Clear" : "Remove"}</Button>
                            : <></>}
                        <Button size="small"
                                onClick={() => setUpsertItemInfo({
                                    title: "Edit Region Mapping",
                                    fields: ["marketKey", "defaultValue", "sdnoValue"],
                                    uneditable: ["marketKey", "sdnoValue"],
                                    initialValues: formData.wholesalerRegionMapping.find(t => t.marketKey === param.id),
                                    handler: onEditItem,
                                    validator: (item) => item.defaultValue?.length !== 0,
                                })}>Edit</Button>
                    </>
                );
            },
            headerClassName: 'mapping-table-header',
        },
    ]

    return (
        <>
            <ToastContainer/>
            <Page name={"Admin Options"}>
                <div>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "95%",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <form className={"admin-options"}>
                            <ComboBox
                                label="Market Keys"
                                value={formData.marketKeys ?? []}
                                data={cachedData.marketKeysLookupTable ? Object.values(cachedData.marketKeysLookupTable) : []}
                                skipSort={true}
                                onChange={(event, value) => {
                                    onUpdateCombobox(
                                        value.map(t => t.value),
                                        "marketKeysIndex",
                                    )
                                }}
                            />

                            <ComboBox
                                label="Designations"
                                value={formData.designations ?? []}
                                data={cachedData.designationsLookupTable ? Object.values(cachedData.designationsLookupTable) : []}
                                skipSort={true}
                                onChange={(event, value) => {
                                    onUpdateCombobox(
                                        value.map(t => t.value),
                                        "designations",
                                    )
                                }}
                            />
                            <div>
                                <h1 style={{fontSize: "20px"}}>Market Region Mapping</h1>
                                <Button variant="contained"
                                        fullWidth={false}
                                        onClick={() => setUpsertItemInfo({
                                            title: "Add Region Mapping",
                                            fields: ["marketKey", "defaultValue"],
                                            uneditable: [],
                                            initialValues: {},
                                            handler: onAddNewItem,
                                            validator: (item) => ((item.defaultValue ?? "").length !== 0) && ((item.marketKey ?? "").length !== 0),
                                        })}>Add</Button>
                            </div>
                            <DataGrid
                                disableVirtualization={process.env.REACT_APP_DISABLE_VIRTUALIZATION ?? false}
                                disableColumnFilter={true}
                                disableColumnMenu={true}
                                slots={{
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }}
                                columns={columns}
                                rows={[...(formData.wholesalerRegionMapping ?? []).sort((a, b) => a.marketKey - b.marketKey)]}
                                getRowId={(row) => row.marketKey}
                                rowHeight={40}
                                data-testid="market-mapping-table"
                                isRowSelectable={() => false}
                                disableColumnSelector={true}
                                hideFooter={true}
                                rowSelection={false}
                                disableRowSelectionOnClick={true}
                            />
                        </form>
                    </Box>
                </div>
            </Page>
            <ConfirmationDialog
                isOpen={Object.keys(confirmationModalInfo).length !== 0}
                message={confirmationModalInfo.message ?? ""}
                handleClose={(_) => setConfirmationModalInfo({})}
                handleConfirm={confirmationModalInfo.handler ?? (() => {
                })}
                title={confirmationModalInfo.title ?? ""}
            />
            <AddEditItemModal
                title={upsertItemInfo.title ?? ""}
                isOpen={Object.keys(upsertItemInfo).length !== 0}
                fields={upsertItemInfo.fields ?? []}
                uneditable={upsertItemInfo.uneditable ?? []}
                initialValues={upsertItemInfo.initialValues ?? {}}
                onClose={() => setUpsertItemInfo({})}
                onSubmit={upsertItemInfo.handler ?? (() => {
                })}
                validateAndEnableSave={upsertItemInfo.validator ?? (() => true)}
            />
            <Backdrop sx={{color: "#FFFFFF", zIndex: (_) => 99999}}
                      open={isLoading}>
                {isLoading && <CircularProgress className={"spinning-loader"} data-testid={"main-loader"}/>}
            </Backdrop>
        </>
    )
}