import axios from "axios";

export const query = async (query) => {
    return await axios.post(
        process.env.REACT_APP_NIRVANA_API_URL,
        {query: query},
        {headers: {"x-api-key": process.env.REACT_APP_NIRVANA_API_KEY}
    }).then((result) => {
        return result.data
    }).catch((error) => {
        console.log(error)
    })
}