import PropTypes from "prop-types";
import BaseModal from "../modals/BaseModal.jsx";

const MarketInsightsCellContainer = ({row}) => {
    const geoFamilyIds = row.geoFamilyIds || ''
    const subcategories = row.subcategories || ''

    return (
        <div className={'permission-cell-container'}>
            {geoFamilyIds ? (
                <div className={'modal-margin-right'}>
                    <BaseModal children={geoFamilyIds?.split(',').join(', ')}
                               openButtonTitle={'GeoFamilyIds'}
                               headerText={'GeoFamilyIds'}
                               buttonClass={'purple-button'}
                    />
                </div>
            ) : <></>}
            {subcategories ? (
                <BaseModal children={subcategories?.split(',').join(', ')}
                           openButtonTitle={'Subcategory'}
                           headerText={'Subcategory'}
                           buttonClass={'purple-button'}
                />
            ) : <></>}
        </div>
    )
};

MarketInsightsCellContainer.propTypes = {
    row: PropTypes.object
};

export default MarketInsightsCellContainer;