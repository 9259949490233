import {useCallback, useEffect, useState} from "react";
import {makeApolloClientCall} from "../../../../helpers/queryNirvanaCoreApi.js";
import {ACCOUNTS_COMPANY_QUERY} from "../queries.js";

export const useFetchAccounts = () => {
    const [accountsCompany, setAccountsCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAccounts = useCallback(async () => {
        const size = 3000;

        const result = await makeApolloClientCall(ACCOUNTS_COMPANY_QUERY, false, {
            page: 1,
            pageSize: size,
        });
        const totalPages = result.data.page.totalPages;
        const range = [...Array(totalPages + 1).keys()].slice(2);
        const companyCalls = range.map((page) =>
            makeApolloClientCall(ACCOUNTS_COMPANY_QUERY, false, {
                page: page,
                pageSize: size,
            })
        );

        const companyResponses = [result].concat(await Promise.all(companyCalls));
        const accounts = companyResponses.flatMap(
            (companyResponse) => companyResponse.data.data
        );
        setAccountsCompany(accounts);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchAccounts().then();
    }, [fetchAccounts]);

    return {accountsCompany, isLoading, fetchAccounts, setAccountsCompany}
}
