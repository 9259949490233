import {GraphqlQueryTable} from "../GraphqlQueryTable.jsx";

export const COMPANY_COLUMNS = () => [
    {
        Header: 'Company Name',
        accessor: 'name'
    },
    {
        Header: 'Salesforce ID',
        accessor: 'id'
    },
    {
        Header: 'Company Type',
        accessor: 'type'
    },
    {
        Header: 'Satori Company Name',
        accessor: 'satoriOrganizationName'
    }
]

export const COMPANY_QUERY = `query {
        data:companies {
            name
            id
            type
            satoriOrganizationName
        }
    }`

export const Company = () => {
    return (<GraphqlQueryTable
        pageName="Company"
        generateColumns={COMPANY_COLUMNS}
        query={COMPANY_QUERY}
    />)
}
