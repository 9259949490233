import React from 'react';
import PropTypes from 'prop-types';
import Auth0UpdateModal from "../modals/Auth0UpdateModal.jsx";
import Auth0DisplayModal from "../modals/Auth0DisplayModal.jsx";

const Auth0CellContainer = ({row}) => {
    const email = row?.email || ''
    return (
        <div className={'auth-cell-container'}>
            <Auth0UpdateModal retailerIdArray={row?.retailerIds || []}
                              marketKeyArray={row?.marketsKeys || []}
                              email={email}
                              openButtonTitle={'Proposed'}
                              headerText={'Auth0 app_metadata'}
            />
            <Auth0DisplayModal
                email={email}
            />
        </div>
    )
};

Auth0CellContainer.propTypes = {
    row: PropTypes.object
};

export default Auth0CellContainer;