function getPreviousPeriod(year, period) {
    let previousYear, previousPeriod;
    if (period === 1) {
        previousYear = year - 1;
        previousPeriod = 13;
    } else {
        previousYear = year;
        previousPeriod = period - 1;
    }
    return { year: previousYear, periodNumber: previousPeriod };
}

export default getPreviousPeriod