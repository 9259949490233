import React from 'react';
import PropTypes from 'prop-types';


function SatoriExtractCreatedDate({createdDate}) {
    return <div className={"gcs-update"}>{`Last updated from Satori: ${createdDate}`}</div>;
}

SatoriExtractCreatedDate.propTypes = {
    createdDate: PropTypes.string.isRequired
};

export default SatoriExtractCreatedDate;