import React, {useEffect, useState} from "react";
import {query} from "../../../helpers/queryNirvanaApi.js";
import Page from "../../Page.jsx";
import {Table} from "../../tables/Table.jsx";

const COLUMNS = [
    {
        Header: 'Market ID',
        accessor: 'market_id'
    },
    {
        Header: 'Market Name',
        accessor: 'market_name'
    },
    {
        Header: 'Subscription ID',
        accessor: 'subscription_id'
    },
    {
        Header: 'Created At',
        accessor: 'created_at'
    },
]

export const Markets = () => {
    const [markets, setMarkets] = useState([])

    useEffect(() => {
        query(`query { markets { created_at market_id market_name subscription_id } }`)
            .then((data) => {
                if (data.errors || !data.data.markets) {
                    setMarkets([{
                        'market_id': 'Please Refresh Page',
                        'market_name': 'Data not fetched',
                        'created_at': ''
                    }])
                } else {
                    setMarkets(data.data.markets)
                }
            })
    }, [])

    return (
        <div>
            <Page name={'Key Account Scorecards'}>
                <div className='table-container' style={{"height": "650px"}}>
                    <Table columns={COLUMNS} data={markets}/>
                </div>
            </Page>
        </div>
    )
}