import React from 'react';
import * as PropTypes from "prop-types";

class Page extends React.Component {

    render() {
        const {name, children, ...otherProps} = this.props;

        return (
            <main {...otherProps}>
                <header className="page-header">
                    <h1>{name}</h1>
                </header>
                <section>
                    {children}
                </section>
            </main>
        );
    }
}

export default Page;

Page.propTypes = {
    name: PropTypes.string,
    children: PropTypes.any,
};