import React, {useEffect, useMemo, useState} from 'react';
import {Table} from "../tables/Table.jsx";
import {makeApolloClientCall} from "../../api/makeApolloApiCall.js";
import {SUMMARY_COLUMNS, SummaryTable} from "../tables/SummaryTable.jsx";
import {getSummary} from "../../helpers/getSummary.js";
import SatoriExtractCreatedDate from "../display/SatoriExtractCreatedDate.jsx";
import Page from "../Page.jsx";
import {addColumnsGroupHeader} from "../tables/util.js";

const COLUMNS = [
    {
        Header: 'Salesforce Name',
        accessor: 'sfName'
    },
    {
        Header: 'Salesforce Email Address',
        accessor: 'sfEmail'
    },
    {
        Header: 'Salesforce Org Name',
        accessor: 'sfOrgName'
    },
    {
        Header: 'Salesforce Org Type',
        accessor: 'sfOrgType'
    },
    {
        Header: 'Satori Email Address',
        accessor: 'satoriEmail'
    },
    {
        Header: 'Satori Username',
        accessor: 'satoriUsername'
    },
    {
        Header: 'Satori Org Name',
        accessor: 'satoriOrganization'
    },
    {
        Header: 'Satori Org Type',
        accessor: 'satoriOrganizationType'
    },
    {
        Header: 'Exists In',
        accessor: 'match'
    }
]

export const Users = () => {
    const [matchedUsers, setMatchedUsers] = useState([])
    const [userDataIsLoading, setUserDataIsLoading] = useState(true)
    const [createdDate, setCreatedDate] = useState('Unknown')

    const usersQuery = `query Users {
        users(limit: 10000) {
            name
            email
            company {
                name
                type
            }
        }
        satoriUsers {
            createdDate
            satoriUser {
                email
                username
                organizationType
                organizationName
            }
        }
    }`

    useEffect(() => {
        makeApolloClientCall(usersQuery)
            .then((data) => {
                setCreatedDate(data.satoriUsers?.createdDate ?? 'Unknown')
                setMatchedUsers(matchUsers(data.users ?? [], data.satoriUsers?.satoriUser ?? []))
                setUserDataIsLoading(false)
            })
    }, [usersQuery])

    const usersSummary = useMemo(() =>
            getSummary(matchedUsers)
        , [matchedUsers])

    return userDataIsLoading ? (<div>Loading...</div>) :
        (
            <Page name='Users'>
                <div className={'table-container'}>
                    <div className={'summary-table'}>
                        <SatoriExtractCreatedDate createdDate={createdDate}/>
                        <SummaryTable columns={SUMMARY_COLUMNS} data={[usersSummary]}/>
                    </div>
                    <Table columns={addColumnsGroupHeader('Data', COLUMNS)} data={matchedUsers}/>
                </div>
            </Page>
        )
}

export const matchUsers = (sfUsers, satoriUsers) => {
    let matchedUsers = []

    satoriUsers?.forEach((item) => {
        const match = item.email ? sfUsers.find((val) => val.email === item.email) : undefined
        matchedUsers.push({
            satoriEmail: item.email,
            satoriOrganization: item.organizationName,
            satoriOrganizationType: item.organizationType,
            sfEmail: match ? match.email : '',
            sfName: match ? match.name : '',
            sfOrgName: match?.company?.name || '',
            sfOrgType: match?.company?.type || '',
            satoriUsername: item.username,
            match: match ? 'BOTH' : 'SATORI'
        })
    })

    sfUsers.forEach((item) => {
        const match = item.email ? matchedUsers?.find((val) => val.satoriEmail === item.email) : undefined
        if (!match) {
            matchedUsers.push({
                sfEmail: item.email,
                satoriEmail: '',
                sfName: item.name,
                sfOrgName: item?.company?.name || '',
                sfOrgType: item?.company?.type || '',
                satoriUsername: '',
                satoriOrganization: '',
                satoriOrganizationType: '',
                match: 'SALESFORCE'
            })
        }
    })

    matchedUsers.sort((a, b) => a.email?.localeCompare(b.email))
    return matchedUsers
}