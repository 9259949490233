import {Button} from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';

export const EditSubscriptionButton = ({editUrl, disabled = false}) => {

    return <div>
        <Button variant="outlined" disabled={disabled}>
            <a href={editUrl}> Edit </a>
        </Button>
    </div>
}
