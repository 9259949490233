import Page from "../../Page.jsx";
import {Table} from "../../tables/Table.jsx";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {CURRENT_PERIOD_QUERY} from "./queries.js";
import {toast, ToastContainer} from "react-toastify";
import getPreviousPeriod from "../../../helpers/getPreviousPeriod.js";

const COLUMNS = [
    {
        Header: 'Company Name',
        accessor: 'brand'
    },
    {
        Header: 'Vendor',
        accessor: 'vendor'
    },
    {
        Header: 'Category Count',
        accessor: 'category_count'
    },
    {
        Header: 'Year',
        accessor: 'year'
    },
    {
        Header: 'Period',
        accessor: 'spins_period'
    },
    {
        Header: 'SPINS Period',
        accessor: 'spins_period_string'
    },
]

export const fetchPeriod = async () => {
    const currentPeriod = (await makeApolloClientCall(CURRENT_PERIOD_QUERY, false, {})).data.abbreviation
    const periodSplit = currentPeriod.split("-")
    const year = periodSplit[0]
    const periodNumber = parseInt(periodSplit[1].replace("P", ""))

    return {
        year,
        periodNumber,
        currentPeriod
    }
}

export const AvailableScorecards = () => {
    const [scorecards, setScorecards] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPeriod, setCurrentPeriod] = useState("")

    const fetchScorecards = async (fixedYear, fixedPeriod) => {
        try {
            setIsLoading(true)
            const {year, periodNumber, currentPeriod}  = (fixedYear && fixedPeriod) ? {year: fixedYear, periodNumber: fixedPeriod, currentPeriod: `${fixedYear}-P${fixedPeriod}` } : await fetchPeriod()
            const {data: { data: scorecardResult}} = await axios.get(`https://api.portal.spins.com/api/v1/scorecard/getAvailableScorecards?includeLength=1&scorecardType=albertsons&year=${year}&period=${periodNumber}`)
            if (scorecardResult.total) {
                setScorecards(scorecardResult.rows)
                setCurrentPeriod(currentPeriod)
                setIsLoading(false)
            } else {
                const {year: prevYear, periodNumber: prevPeriodNumber} = getPreviousPeriod(year, periodNumber)
                return fetchScorecards(prevYear, prevPeriodNumber)
            }
        } catch (error) {
            toast.error(`An error occurred while fetching the scorecard list. ${error}`)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchScorecards()
    }, [])

    if (isLoading) {
        return (<CircularProgress className={"spinning-loader"} data-testid={"main-loader"}/>)
    }

    return (
        <div>
            <ToastContainer/>
            <Page name={`Available NOSHE Scorecards (${currentPeriod})`}>
                <div className='table-container' style={{"height": "650px"}}>
                    <Table columns={COLUMNS} data={scorecards}/>
                </div>
            </Page>
        </div>
    )
}