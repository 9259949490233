import React, {useEffect, useState} from 'react'
import {query} from "../../../helpers/queryNirvanaApi.js";
import {Table} from "../../tables/Table.jsx";
import Page from "../../Page.jsx";
import {toast, ToastContainer} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {Button} from "@mui/material";

const COLUMNS = [
    {
        Header: 'Company Name',
        accessor: 'company_name'
    },
    {
        Header: 'Subscription',
        accessor: 'subscription'
    },
    {
        Header: 'Subscription UUID',
        accessor: 'subscription_uuid'
    },
    {
        Header: 'Contract UUID',
        accessor: 'contract_uuid'
    },
    {
        Header: 'Status',
        accessor: 'status'
    },
    {
        Header: 'Created At',
        accessor: 'created_at'
    },
]

const updateSubscription = async (contract_uuid, subscription_uuid) => {
    return await query(
        `mutation {
            deactivateSubscription(
                subscription_uuid: "${subscription_uuid}",
                contract_uuid: "${contract_uuid}"
            )
        }`)
}

export const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([])

    const deactivateSubscription = async (values, setSubmitting) => {
        setSubmitting(true)
        const {contract_uuid, subscription_uuid} = values

        const deactivateResult = await updateSubscription(contract_uuid, subscription_uuid)

        if (JSON.parse(deactivateResult.data.deactivateSubscription).sqlStatementResults[0].numberOfRecordsUpdated) {
            toast.success('Subscription deactivated, please refresh page')
        } else {
            toast.error('Error, Try Again')
        }

        setSubmitting(false)
    }

    useEffect(() => {
        query(`query { subscriptions { subscription company_name subscription_uuid contract_uuid status created_at } }`)
            .then((data) => {
                if (data.errors || !data.data.subscriptions) {
                    setSubscriptions([{
                        'company_name': 'Please Refresh Page',
                        'subscription': 'Data not fetched',
                        'contract_uuid': '',
                        'subscription_uuid': '',
                        'status': ''
                    }])
                } else {
                    setSubscriptions(data.data.subscriptions)
                }
            })
    }, [])

    return (
        <div>
            <div className='page-wrapper'>
                <Page name={'Scorecard Subscriptions'}>
                    <ToastContainer/>
                    <div className='form' style={{width: '80%'}}>
                        <h1>Deactivate Scorecards Subscription</h1>
                        <Formik
                            initialValues={{
                                contract_uuid: '',
                                subscription_uuid: '',
                            }}
                            onSubmit={async (values, {setSubmitting}) => {
                                await deactivateSubscription(values, setSubmitting)
                            }}
                        >
                            {({
                                  isSubmitting
                              }) => (
                                <Form>
                                    <div>
                                        <label>Subscription UUID</label>
                                        <Field name="subscription_uuid" aria-label="subscription_uuid" type="text"/>

                                        <label>Contract UUID</label>
                                        <Field name="contract_uuid" aria-label="contract_uuid" type="text"/>
                                    </div>

                                    <Button variant="outlined" color="error" type="submit" disabled={isSubmitting}
                                            aria-label={'deactivate-subscription'}>Deactivate</Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Page>
                <div className='table-container' style={{"width": "50%"}}>
                    <Table columns={COLUMNS} data={subscriptions}/>
                </div>
            </div>
        </div>
    )
}