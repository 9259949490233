import {useEffect, useState} from "react";
import {makeApolloClientCall} from "../../../../../api/makeApolloApiCall.js";
import {toast} from "react-toastify";
import {Button} from "@mui/material";
import ConfirmationDialog from "../../dialog/ConfirmationDialog.jsx";


export const RunAllButton = ({isQuarter, idResolver, data, query, labelOverride}) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const ids = idResolver(isQuarter, data)

    const handleConfirm = (value) => {
        if(value) {
            setIsDisabled(true)
            setOpenModal(false)
            makeApolloClientCall(query, false, {ids})
                .then(() => toast.success("done"))
                .catch(error => toast.error(`${error}`))
                .finally(() => {
                    setIsDisabled(false)
                })
        } else {
            setOpenModal(false)
        }
    }

    const run = isQuarter ? "Quarter Run" : "Monthly Run"
    return (
        <div>
            <Button
                variant="outlined"
                disabled={isDisabled}
                onClick={() => setOpenModal(true)}
            >
                {labelOverride ? labelOverride : run}
            </Button>
            <ConfirmationDialog
                isOpen={openModal}
                message={`Are you sure you want to execute ${run}?`}
                handleConfirm={handleConfirm}
                title={"Confirm Run"}
            />
        </div>
    )

}