import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Button, Divider, Grid} from "@mui/material";
import {toast} from "react-toastify";
import {Dropdown} from "../../../dropdown/Dropdown.jsx";


const LIST_FILE_API = (scorecardType) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/${scorecardType}/list`;
const DOWNLOAD_FILE_API = (scorecardType, filename) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/${scorecardType}/download?filename=${encodeURI(filename)}`;
const UPLOAD_FILE_API = (scorecardType) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/${scorecardType}/upload`;
const REFORMAT_FILE_API = (scorecardType) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/${scorecardType}/reformat`;

const SCORECARD_TYPE = {
    INFRA: 'infra',
    NCG: 'ncg',
    NOSHE: 'noshe'
}
const REFORMAT_TYPE = {
    straight: 'straight-upload', // Straight from gcs
    manual: 'manual-upload',
}
const SCORECARD_TYPE_DATA = Object.entries(SCORECARD_TYPE).map(([k, v]) => {
    return {
        value: v,
        displayName: k
    }
})

function bytesToSize(bytes) {
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}


export const FileUpload = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(SCORECARD_TYPE.INFRA);

    const [fileNames, setFileNames] = useState([]);
    const [downloadFile, setDownloadFile] = useState(null)

    const [uploadFile, setUploadFile] = useState(null);
    const auth0AccessToken = window.localStorage.getItem('access_token')
    const fileRef = useRef();


    const isNOSHE = type === SCORECARD_TYPE.NOSHE;

    const handleFileChange = (e) => {
        const selectedFile = e.target.files && e.target.files[0];
        setUploadFile(selectedFile || null);
    };
    const clearValueRef = () => {
        setUploadFile(null)
        if (fileRef?.current) {
            fileRef.current.value = '';
        }
    }
    const handleUpload = async () => {
        setIsLoading(true)
        if (isNOSHE && downloadFile) {

            const response = await axios.post(
                UPLOAD_FILE_API(type),
                {
                    filename: downloadFile
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${auth0AccessToken}`

                    },
                }
            );
            setIsLoading(false)
            return toast.success(`Uploaded to: ${response?.data?.url}`)
        }

        if (uploadFile) {
            try {
                const formData = new FormData();
                formData.append("file", uploadFile);

                await axios.post(
                    UPLOAD_FILE_API(type),
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': `Bearer ${auth0AccessToken}`

                        },
                    }
                ).then(({data}) => toast.success(`Uploaded to: ${data?.url}`));
            } catch (error) {
                console.error(error);
                clearValueRef()
                toast.error(error.message);
            }
        }
        setIsLoading(false)
    };

    const handleReformat = (reformatType) => async () => {
        setIsLoading(true)
        try {
            let requestObj;
            let contentType;
            let method;
            if (reformatType === REFORMAT_TYPE.manual && uploadFile) {
                const formData = new FormData();
                formData.append("file", uploadFile);
                requestObj = formData
                contentType = "multipart/form-data;application/json"
                method = "POST"
            } else if (reformatType === REFORMAT_TYPE.straight && downloadFile) {
                requestObj = {filename: downloadFile}
                contentType = "application/json"
                method = "PUT"
            }
            await axios.request({
                url: REFORMAT_FILE_API(type),
                data: requestObj,
                headers: {
                    "Content-Type": contentType,
                    'Authorization': `Bearer ${auth0AccessToken}`
                }, method
            }).then(({data}) => toast.success(`Uploaded to: ${data?.url}`))

        } catch (error) {
            console.error(error);
            clearValueRef()
            toast.error(error.message)
        }
        setIsLoading(false)
    };
    const handleDownload = async () => {
        setIsLoading(true)
        await axios.get(DOWNLOAD_FILE_API(type, downloadFile), {
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Authorization': `Bearer ${auth0AccessToken}`
            },
            responseType: "blob"
        }).then(response => {
            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = downloadFile;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
                console.log('error: ', error);
            });
    };


    useEffect(() => {
        let isValid = true;
        const abortController = new AbortController()
        setIsLoading(true)
        const fetchFilenames = async (scorecardType) => {
            return await axios.get(LIST_FILE_API(scorecardType), {
                headers: {
                    'authorization': `Bearer ${auth0AccessToken}`
                },
                signal: abortController.signal
            })
        }
        fetchFilenames(type).then(({data}) => {
            data.sort((a, b) => b.lastModified?.localeCompare(a.lastModified))

            if (isValid) {
                setFileNames(data.map((d) => ({...d, lastModified: new Date(d.lastModified)})))
                setDownloadFile(data[0]?.filename)
                clearValueRef()
            }
        }).finally(() => {
            setIsLoading(false)
        })
        return () => {
            isValid = false
            abortController.abort()
        }
    }, [auth0AccessToken, setIsLoading, type]);

    const filenamesData = fileNames.map((filename) => {
        return {
            value: filename.filename,
            displayName: `[${filename.lastModified?.toLocaleDateString() ?? ''}] ${filename.filename}${filename.fileSize ? ` (${bytesToSize(filename.fileSize)})` : ''} `
        }
    })

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
                <div className={'score-card-container'}>
                    <Dropdown
                        label={'Score Card Type'}
                        data={SCORECARD_TYPE_DATA}
                        currentValue={type}
                        onChange={(e) => setType(e.target.value)}
                        styles={'score-card-combobox'}
                        testId={'type-dropdown'}
                    />
                </div>
            </Grid>

            <Grid item xs={8} md={8} style={{alignItems: 'center', display: 'flex'}}>
                <Dropdown label={filenamesData?.length === 0 ? "No Files" : 'Filename'} data={filenamesData}
                          disabled={isLoading || filenamesData?.length === 0}
                          onChange={(e) => setDownloadFile(e.target.value)}
                          currentValue={downloadFile ?? filenamesData?.[0]?.value ?? ''}
                          styles={'file-combobox'}
                />
                <Button
                    sx={{marginLeft: 1}}
                    disabled={isLoading || !downloadFile}
                    onClick={isNOSHE ? handleUpload : handleDownload}>
                    {isNOSHE ? 'UPLOAD' : 'DOWNLOAD'}
                </Button>
                {
                    !isNOSHE &&
                    <Button
                        sx={{marginLeft: 1}}
                        disabled={isLoading}
                        onClick={handleReformat(REFORMAT_TYPE.straight)}>
                        REFORMAT AND UPLOAD
                    </Button>
                }
            </Grid>

            {
                !isNOSHE && (
                    <>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={2} md={2}/>
                        <Grid item xs={8} md={8}>
                            <Divider variant="middle" component="div"
                                     sx={{borderWidth: '1px', borderColor: '#252525'}}/>
                        </Grid>
                        <Grid item xs={2} md={2}/>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={12} md={12}/>
                        <Grid item xs={12} md={12} className={'upload'}>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <label
                                    htmlFor="file-upload"
                                    className={'custom-file-upload'}
                                >
                                    {uploadFile?.name ?? 'Choose file'}

                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    ref={fileRef}
                                />
                                <Button
                                    onClick={handleUpload}
                                    disabled={!uploadFile || isLoading}>
                                    UPLOAD
                                </Button>
                                <Button
                                    onClick={handleReformat(REFORMAT_TYPE.manual)}
                                    disabled={!uploadFile || isLoading}>
                                    REFORMAT AND UPLOAD
                                </Button>
                            </div>
                        </Grid>
                    </>

                )
            }
        </Grid>

    );
}