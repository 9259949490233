import React, {useState} from 'react';
import PropTypes from 'prop-types'
import chevronDown from "../../assets/chevron-down.svg";


const HeaderDropdown = ({title, menuItems, tag}) => {
    const [showDropdown, setShowDropdown] = useState(false)


    return (
        <div className={'divider'} key={title}>
            <ul className="menu dropdown columns small-12 header-dropdowns" data-dropdown-menu={true}>
                <li className={"columns small-1"}>
                    <button className={`dropdown-menu-link-large`}
                            onMouseEnter={() => setShowDropdown(true)}
                            onMouseLeave={() => setShowDropdown(false)}>
                        <span
                            className={`${menuItems.some(menu => menu.url === window.location.pathname) ? 'active' : ''}`}>{title}</span>
                        {tag && <sup className={"title-tag"}>{"BETA"}</sup>}
                        <img alt={'chevron'} className={'chevron-down'} src={chevronDown}/>
                    </button>


                    <ul className="menu vertical ingest-status-list" style={{border: 'none'}} data-dropdown-menu={true}>
                        {showDropdown && (
                            <div style={{border: '1px solid #D9D9D9'}} onMouseEnter={() => setShowDropdown(true)}
                                 onMouseLeave={() => setShowDropdown(false)}>
                                {menuItems.map((item, i) =>
                                    item === '-' ? (
                                        <li key={`${item}-${i}`}>
                                            <div className="separator"/>
                                        </li>
                                    ) : (
                                        <li key={`${item.name}-${i}`} className={"columns small-1 testing"}>
                                            <a
                                                href={item.url}
                                                id={`${item.name}-dropdown`}
                                                className={`dropdown-menu-link-large ${window.location.pathname === item.url ? 'active' : ''}`}
                                            >
                                                <div className={'dropdown-menu-link-container'}>
                                                    {item.name}
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                            </div>)}
                    </ul>
                </li>
            </ul>


        </div>

    )
}

HeaderDropdown.defaultProps = {
    nestedContent: undefined
}

HeaderDropdown.propTypes = {
    title: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.oneOfType(
            [
                PropTypes.string,
                PropTypes.shape({
                    name: PropTypes.string,
                    url: PropTypes.string,
                    nestedContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
                })
            ])
    )
};

export default HeaderDropdown;