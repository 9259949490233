import React, {useEffect, useMemo, useState} from 'react'
import {Table} from "../tables/Table.jsx"
import {makeApolloClientCall} from "../../api/makeApolloApiCall.js"
import {SUMMARY_COLUMNS, SummaryTable} from "../tables/SummaryTable.jsx"
import SatoriExtractCreatedDate from "../display/SatoriExtractCreatedDate.jsx";
import {getSummary} from "../../helpers/getSummary.js";
import Page from "../Page.jsx";
import {addColumnsGroupHeader} from "../tables/util.js";

const COLUMNS = [
    {
        Header: 'Brand Name',
        accessor: 'brand',
        Cell: row => <div className={'column-align-left'}>{row.value}</div>
    },
    {
        Header: 'Company Name',
        accessor: 'company',
        Cell: row => <div className={'column-align-left'}>{row.value}</div>
    },
    {
        Header: 'Relationship Types',
        accessor: 'relationshipTypes',
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: 'Exists In',
        accessor: 'match'
    },
]

export const Manufacturers = () => {
    const [companyBrandDataIsLoading, setCompanyBrandDataIsLoading] = useState(true)
    const [matchedCompanyBrands, setMatchedCompanyBrands] = useState([])
    const [createdDate, setCreatedDate] = useState('Unknown')

    const companyBrandsQuery = `query CompanyBrands {
        companyBrands {
            brand
            company
            relationshipTypes
        }
          getBrandsByManufacturer {
            createdDate
            manufacturerBrand {
                brandName
                organizationName
            }
        }
    }`

    useEffect(() => {
        makeApolloClientCall(companyBrandsQuery)
            .then((data) => {
                setCreatedDate(data.getBrandsByManufacturer?.createdDate ?? 'Unknown')
                setMatchedCompanyBrands(matchCompanyBrands(
                    data.companyBrands ?? [],
                    data.getBrandsByManufacturer ?? []))
                setCompanyBrandDataIsLoading(false)
            })
    }, [companyBrandsQuery])

    const companyBrandsSummary = useMemo(() =>
            getSummary(matchedCompanyBrands)
        , [matchedCompanyBrands])

    return (companyBrandDataIsLoading) ? (<div>Loading...</div>) :
        (
            <Page name='Manufacturers Brand'>
                <div className={'table-container'}>
                    <div className={'summary-table'}>
                        <SatoriExtractCreatedDate createdDate={createdDate}/>
                        <SummaryTable columns={SUMMARY_COLUMNS} data={[companyBrandsSummary]}/>
                    </div>
                    <Table columns={addColumnsGroupHeader('Data', COLUMNS)} data={matchedCompanyBrands}/>
                </div>
            </Page>
        )
}

export const matchCompanyBrands = (sfCompanyBrands, satoriCompanyBrands) => {
    let matchedCompanyBrands = []
    sfCompanyBrands.forEach((item) => {
        const match = satoriCompanyBrands?.manufacturerBrand?.find((val) => val.brandName === item.brand && val.organizationName === item.company)

        matchedCompanyBrands.push({
            company: item.company,
            brand: item.brand,
            relationshipTypes: item.relationshipTypes,
            match: match ? 'BOTH' : 'SALESFORCE',
        })
    })

    satoriCompanyBrands?.manufacturerBrand?.forEach((item) => {
        const match = matchedCompanyBrands.find((val) => (val.brand === item.brandName && val.company === item.organizationName))

        if (!match) {
            matchedCompanyBrands.push({
                company: item.organizationName,
                brand: item.brandName,
                relationshipTypes: '',
                match: 'SATORI'
            })
        }
    })

    matchedCompanyBrands.sort((a, b) => a.brand.localeCompare(b.brand))

    return matchedCompanyBrands
}

