import {useTable} from "react-table";
import React from "react";

export const SUMMARY_COLUMNS = [
    {
        Header: 'Summary',
        columns: [
            {
                Header: 'Salesforce',
                accessor: 'SALESFORCE'
            },
            {
                Header: 'Satori',
                accessor: 'SATORI'
            },
            {
                Header: 'Both',
                accessor: 'BOTH'
            },
        ]
    }
]

export const SummaryTable = ({columns, data}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
            columns,
            data,
        }
    )

    return (
        <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}