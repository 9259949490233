import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import axios from "axios";
import {getAuth0ApiAccessToken} from "../../helpers/getAuth0ApiAccessToken.js";

const Auth0UpdateModal = ({
                              retailerIdArray,
                              marketKeyArray,
                              email,
                              actionButtonTitle,
                              openButtonTitle,
                              closeButtonTitle,
                              headerText
                          }) => {
    const [modalAction, handleModalAction] = useState(false)
    const [appMetadata, setAppMetadata] = useState(undefined)
    const [disableUpsert, setDisableUpsert] = useState(false)

    async function handleOpenModal() {
        handleModalAction(true)
    }

    function handleCloseModal() {
        handleModalAction(false)
    }

    async function handleAuth0Upsert() {
        setDisableUpsert(true)
        await getAuth0ApiAccessToken()

        const url = process.env.REACT_APP_ENV === 'prod' ? 'https://spinsllc-prod.auth0.com' : 'https://spinsllc.auth0.com'

        const auth0AccessToken = window.localStorage.getItem('auth0_api_access_token')
        await axios.get(`${url}/api/v2/users-by-email?email=${email}`,
            {headers: {'authorization': `Bearer ${auth0AccessToken}`}})
            .then(async r => {
                if (r.data.length) {
                    await axios.patch(`${url}/api/v2/users/${r.data[0].user_id}`,
                        {"app_metadata": {"retailerIds": retailerIdArray, "marketKeys": marketKeyArray}},
                        {headers: {'authorization': `Bearer ${auth0AccessToken}`}})
                        .then(() => {
                            setDisableUpsert(false)
                        }).catch(error => {
                            console.error(error)
                        })
                } else {
                    let randPassword = Array(15).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
                        .map(x => x[Math.floor(Math.random() * x.length)]).join('');
                    await axios.post(`${url}/api/v2/users`,
                        {
                            email: email,
                            app_metadata: {retailerIds: retailerIdArray, marketKeys: marketKeyArray},
                            connection: 'Username-Password-Authentication',
                            name: email,
                            password: randPassword
                        },
                        {headers: {'authorization': `Bearer ${auth0AccessToken}`}}
                    ).then(() => {
                        setDisableUpsert(false)
                    }).catch(error => {
                        console.error(error)
                    })
                }
            }).catch(error => {
                console.error(error)
            })
    }

    function formatAppMetadata(retailers, markets) {
        const appMetadataObject = {
            marketKeys: markets,
            retailerIds: retailers
        }
        setAppMetadata(appMetadataObject)
    }

    useEffect(() => {
        formatAppMetadata(retailerIdArray, marketKeyArray)
    }, [retailerIdArray, marketKeyArray])

    return (
        <div className={'update-modal'}>
            <button className={'button'} onClick={handleOpenModal}>{openButtonTitle}</button>
            <Modal
                isOpen={modalAction}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                contentLabel="Minimal Modal Example"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className={"modal-header"}>
                    <div><h5>{headerText}</h5></div>
                </div>
                <div className={'modal-content'}>{JSON.stringify(appMetadata, undefined, '\t')}</div>
                <button className={'button modal-button action'}
                        onClick={handleAuth0Upsert}
                        disabled={disableUpsert}>
                    {actionButtonTitle}
                </button>
                <button className={'button modal-button cancel'} onClick={handleCloseModal}>{closeButtonTitle}</button>
            </Modal>
        </div>)
        ;
};

Auth0UpdateModal.defaultProps = {
    actionButtonTitle: 'Upload',
    closeButtonTitle: 'Close',
    openButtonTitle: 'Auth0 Graphiql Metadata'
};

Auth0UpdateModal.propTypes = {
    auth0AccessToken: PropTypes.string,
    actionButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    email: PropTypes.string,
    headerText: PropTypes.string,
    marketKeyArray: PropTypes.array,
    openButtonTitle: PropTypes.string,
    retailerIdArray: PropTypes.array
};

export default Auth0UpdateModal;
