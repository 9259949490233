import React, {useState} from 'react';
import axios from "axios";
import PropTypes from "prop-types";
import Modal from "react-modal";
import {getAuth0ApiAccessToken} from "../../helpers/getAuth0ApiAccessToken.js";

const Auth0DisplayModal = ({email}) => {
    const [auth0UserDataResponse, setAuth0UserDataResponse] = useState(undefined)
    const [modalAction, handleModalAction] = useState(false)

    async function handleOpenModal() {
        await getAuth0ApiAccessToken()
        const auth0AccessToken = window.localStorage.getItem('auth0_api_access_token')
        const url = process.env.REACT_APP_ENV === 'prod' ? 'https://spinsllc-prod.auth0.com' : 'https://spinsllc.auth0.com'

        await axios.get(`${url}/api/v2/users-by-email?email=${email}`,
            {headers: {'authorization': `Bearer ${auth0AccessToken}`}})
            .then(r => {
                if(r.data.length) {
                    const response = r.data[0]?.app_metadata ? JSON.stringify(r.data[0].app_metadata, undefined, '\t')
                        : 'This user exists in Auth0 but with no app_metadata'
                    setAuth0UserDataResponse(response)
                }
                else {
                    setAuth0UserDataResponse('This user does not exist in Auth0')
                }
                handleModalAction(true)
            }).catch(error => {
                setAuth0UserDataResponse(`Error: ${error}`)
                handleModalAction(true)
                console.error(error)
            })
    }

    function handleCloseModal() {
        handleModalAction(false)
    }


    return (
        <div>
            <button className={'button'} onClick={handleOpenModal}>{'Current'}</button>
            <Modal
                isOpen={modalAction}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                contentLabel="Minimal Modal Example"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className={"modal-header"}>
                    <div><h5>{'Auth0 app_metadata'}</h5></div>
                </div>
                <div className={'modal-content'}>{auth0UserDataResponse}</div>
                <button className={'button modal-button cancel'} onClick={handleCloseModal}>{'Close'}</button>
            </Modal>
        </div>
    )
};
Auth0DisplayModal.propTypes = {
    auth0AccessToken: PropTypes.string,
    email: PropTypes.string
};

export default Auth0DisplayModal;