import axios from "axios";
import jwtDecode from "jwt-decode";

export const RI_AUDIT_LOG_API = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/audit-logs/log`;

export const createAuditLogEntry = async (endpoint, data) => {
	const auth0AccessToken = window.localStorage.getItem("access_token");
	const axiosHeader = {
		Authorization: `Bearer ${auth0AccessToken}`,
	};
	const userEmail = auth0AccessToken
		? jwtDecode(auth0AccessToken)["https://aot/email"]
		: "";

	await axios
		.post(
			endpoint,
			{
				data: {
					...data,
					email: userEmail,
					env: process.env.REACT_APP_ENV,
				},
			},
			{
				headers: axiosHeader,
			}
		)
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log("error: ", error);
		});
};
