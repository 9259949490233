import _ from 'lodash'
import moment from "moment";
function enhanceRetailers(item, retailers) {
    let retailerArray = []
    let retailerIds = []
    const retailTagsArray = item?.retailTags?.split(',')
    retailTagsArray && retailTagsArray.forEach((tag) => {
        const match = retailers?.find((val) => val.retailTag === parseInt(tag))
        if (match) {
            retailerIds.push(match.retailerId)
            retailerArray.push(`${match.retailerId} - ${match.retailChain}`)
        }
        else {
            retailerArray.push(`No retailer service match for Retail Tag: ${tag}`)
        }
    })
    return {retailers: retailerArray.sort(), retailerIds};
}

function enhanceMarkets(item, marketsApiReturn, geoFamilyIds) {
    let marketArray = []
    let marketsKeys = []
    const geoFamilyIdArray = item?.geoFamilyIds?.split(',')
    geoFamilyIdArray && geoFamilyIdArray.forEach((tag) => {
        const match = geoFamilyIds?.find((val) => val.geoFamilyId === parseInt(tag))
        if (match)
            match.marketList.forEach(market => {
                const matchedMarket = marketsApiReturn.find((x) => x.market_key === market)
                if(matchedMarket) {
                    marketsKeys.push(parseInt(matchedMarket.market_key))
                    marketArray.push(`${matchedMarket.market_key} - ${matchedMarket.description}`)
                }
                else{
                    marketArray.push(`No match for Market: ${market}`)
                }
            })

        else {
            marketArray.push(`No GeoFamily ACL match for GeoFamilyId: ${tag}`)
        }
    })
    return {markets: marketArray.sort(), marketsKeys};
}

export function mergeRetailerAndMarketDataWithUser(satoriUsers, retailers, marketsApiReturn, geoFamilyIds, sfUsers, subcats){
    if(!satoriUsers)
        return []
    let usersArrayReturn = []
    satoriUsers.forEach((item) => {
        if(item.email) {
            item.email = item.email.toLocaleLowerCase()
            item.subcategories = subcats.find(e => e.username === item.username)?.subcategories
            const sfUser = sfUsers.find((user) => item.email === user.email)
            if(sfUser) {
                item.companyTpa = sfUser.company?.tpa ? 'Active' : 'Inactive'
                item.companyName = sfUser.company?.name
            }
            const enhancedRetailers = enhanceRetailers(item, retailers);
            const enhancedMarkets = enhanceMarkets(item, marketsApiReturn, geoFamilyIds);
            const enhancedUser = {
                ...item,
                ...enhancedRetailers,
                ...enhancedMarkets,
                ...{'sfUser': sfUser !== undefined}
            }
            usersArrayReturn.push(enhancedUser)
        }
    });

    return usersArrayReturn
}

export function mapCompanies(salesforceCompanies, satoriCompanies) {
    let companies = []

    !_.isEmpty(satoriCompanies) && satoriCompanies?.satoriCompanies.forEach(x => {
        companies.push({
            salesforceId: x.salesForceClientId,
            name: x.organizationName,
            enabled: x.enabled ? 'Activated' : 'Deactivated',
            contractEndDate: x.contractEndDate,
            contractNumber: x.contractNumber
        })
    })

    !_.isEmpty(salesforceCompanies) && salesforceCompanies?.forEach(nodes => {
        companies.push({
            salesforceId: nodes?.id,
            name: nodes?.name,
            masterContractNumber: nodes?.contracts[0]?.masterContractNumber,
            contractNumber: nodes?.contracts[0]?.contractNumber,
            contractEndDate: moment(nodes?.contracts[0]?.contractEndDate).format('MM/DD/YYYY'),
            enabled: nodes?.contracts[0]?.contractStatus
        })
    })

    return companies
}