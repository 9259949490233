import {Formik, Field, Form} from "formik";
import {Button} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import {query} from "../../../helpers/queryNirvanaApi.js";
import React, {useEffect, useState} from "react";
import {Table} from "../../tables/Table.jsx";
import {toast, ToastContainer} from "react-toastify";

const COLUMNS = [
    {
        Header: 'Company',
        accessor: 'company_name'
    },
    {
        Header: 'Account UUID',
        accessor: 'account_uuid'
    },
]

const insertContract = async (contract_start_date, contract_end_date, contract_number, account_uuid, contract_uuid) => {
    return await query(
        `mutation {
            insertContract(
                contract: {
                    contract_start_date: "${contract_start_date}",
                    contract_end_date: "${contract_end_date}",
                    contract_number: "${contract_number}",
                    account_uuid: "${account_uuid}",
                    contract_uuid: "${contract_uuid}"
                }
            )
        }`)
}

export const CreateContract = () => {
    const [companies, setCompanies] = useState([])

    const createContract = async (values, setSubmitting) => {
        setSubmitting(true)
        const {contract_start_date, contract_end_date, contract_number, account_uuid} = values
        const contract_uuid = uuidv4()
        const contract_result = await insertContract(contract_start_date, contract_end_date, contract_number, account_uuid, contract_uuid)

        if(contract_result.errors) {
            toast.error('Error, try again')
        } else if(JSON.parse(contract_result?.data?.insertContract).sqlStatementResults[0].numberOfRecordsUpdated){
            toast.success('Contract created')
        } else {
            toast.error('Error, try again')
        }

        setSubmitting(false)
    }

    useEffect(() => {
        query(`query { companies { account_uuid company_name } }`
        ).then((data) => {
            if(data.errors || !data.data.companies) {
                setCompanies([{'company_name': 'Please Refresh Page', 'account_uuid': 'Data not fetched'}])
            } else {
                setCompanies(data.data.companies)
            }
        })
    }, [])

    return (
        <div className='page-wrapper'>
            <ToastContainer/>
            <div className='form'>
                <h1>Create Scorecards Contract</h1>
                <Formik
                    initialValues={{
                        account_uuid: '',
                        contract_number: '',
                        contract_start_date: '',
                        contract_end_date: '',
                    }}
                    onSubmit={async (values, {setSubmitting}) => {
                        await createContract(values, setSubmitting)
                    }}
                >
                    {({
                          isSubmitting
                      }) => (
                        <Form>
                            <div>
                                <label>Account UUID</label>
                                <Field name="account_uuid" aria-label="account_uuid" type="text"/>

                                <label>Contract Number (not used, use current period)</label>
                                <Field name="contract_number" aria-label="contract_number" placeholder="14005"
                                       type="text"/>

                                <label>Contract Start Date</label>
                                <Field name="contract_start_date" aria-label="contract_start_date"
                                       placeholder="2022-12-31" type="text"/>

                                <label>Contract End Date</label>
                                <Field name="contract_end_date" aria-label="contract_end_date"
                                       placeholder="2025-12-31"
                                       type="text"/>
                            </div>

                            <Button variant="outlined" type="submit" disabled={isSubmitting}
                                    aria-label={'create-contract'}>Create</Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className='table-container' style={{"width": "50%"}}>
                <Table columns={COLUMNS} data={companies}/>
            </div>
        </div>
    )
}