import React from 'react'
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export const Dropdown = ({label, data, onChange, currentValue, required, styles, testId, disabled = false, sx={}}) => {
    return (
        <FormControl
            className={styles}
            required={required}>
            <InputLabel>{label}</InputLabel>
            <Select
                disabled={disabled}
                aria-describedby={label}
                data-testid={testId || label}
                onChange={onChange}
                label={label}
                value={currentValue}
                role="select"
                sx={sx}
            >
                {
                    data?.map(item => {
                        return (<MenuItem
                            key={item.value}
                            value={item.value}>{item.displayName}</MenuItem>)
                    })
                }
            </Select>
        </FormControl>
    );
}
