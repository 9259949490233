export const GEO_FAMILY_QUERY = `query {
  geoFamilyIds: geoFamily {
       geo_family_id
       geo_family_description
       major_channel_code
  }
}`

export const MDM_BRANDS_QUERY = `query {
  mdmBrands {
    edges {
      node {
        value: nodeId
        displayName: name
      }
    }
  }
}        
`

export const SUBCATEGORIES_QUERY = `query {
  sdnDeliveryPeriodSnapshotSubcategories {
    edges {
      node {
        value: tag
        displayName: description        
      }
    }
  }
}`

export const CATEGORIES_QUERY = `query  {
  sdnDeliveryPeriodSnapshotCategories {
    edges {
      node {
        value: tag
        displayName: description
        subcategories {
          edges {
            node {
              value: tag
              displayName: description
            }
          }
        }
      }
    }
  }
}`

export const COMPANIES_QUERY = `query($after: String){
  mdmCompanies(first: 20000, after: $after, filter: {
    externalReferenceType: {
      equalTo: "type"
    },
    externalReferenceSystem: {
      equalTo: "salesforce"
    },
    externalReferenceIdentifier: {
      equalTo: "Manufacturer"
    }
  }) {
    edges {
      node {
        displayName: name
        externalReferences {
          system
          identifier
          type
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`

export const DEPARTMENTS_QUERY = `query{
    sdnDeliveryPeriodSnapshotDepartments {
        edges {
          node {
            displayName: description
            value: tag
            categories {
              edges {
                node {
                  displayName: description
                  value: tag
                  subcategories {
                    edges {
                      node {
                        displayName: description
                        value: tag
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
}`