import '../../../scss/PowerTabs.scss';
import * as React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {SubscriptionForm} from "./SubscriptionForm.jsx";
import {ALL_BRANDS, DEFAULT_AGGREGATION_PERIODS, PRODUCT_UNIVERSES} from "./constants.js";

export const DEFAULT_REPORT_TYPE = "Base";
export const DEFAULT_UPC_PRODUCT_UNIVERSE = "TPL";

export const INITIAL_STATE = {
    report_type: DEFAULT_REPORT_TYPE,
    aggregateProductUniverses: PRODUCT_UNIVERSES,
    upcProductUniverse: DEFAULT_UPC_PRODUCT_UNIVERSE,
    aggregationPeriods: DEFAULT_AGGREGATION_PERIODS,
    competitorBrands: ALL_BRANDS
};
export const CreateSubscription = () => {
    return (<SubscriptionForm initialState={INITIAL_STATE}/>)
}


